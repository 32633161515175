import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Loader = () => {
  return (
    <div className="min-h-screen flex justify-center items-center">
      <AiOutlineLoading3Quarters
        size="5rem"
        color="#0A66C2"
        className="animate-spin"
      />
    </div>
  );
};

export default Loader;
