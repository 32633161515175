import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Nav from "../Nav";
import { Container, Flex, Hide } from "@chakra-ui/react";
import UserProfile from "../Profile/UserProfile";
import Suggestions from "../Suggestions";
import { paths } from "../../routes";

const MainLayout = () => {
  const location = useLocation();
  const showProfile =
    location.pathname === paths.PROFILE || location.pathname === paths.USER;

  return (
    <Container maxW="8xl" px={{ base: 4, xl: 12 }} py={{ base: 2, lg: 6 }}>
      <Nav />
      <Flex
        flexDir={{ base: "column", md: "row" }}
        gap={8}
        my={{ base: 6, lg: 16 }}
      >
        {showProfile ? (
          <UserProfile />
        ) : (
          <Hide below="md">
            <UserProfile />
          </Hide>
        )}
        <Flex flexBasis={{ lg: "66.66%" }} justifyContent="center" w="full">
          <Outlet />
        </Flex>
        <Suggestions />
      </Flex>
    </Container>
  );
};

export default MainLayout;
