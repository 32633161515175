import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

const PurposeOfProcessing = () => {
  return (
    <Box w="100%" my="40px">
      <Heading fontSize="16px">What is personal data?</Heading>

      <Text w="100%" lineHeight={1.5} my="20px">
        We collect information about you in a range of forms, including personal
        data. As used in this Policy, “personal data” is as defined in the
        General Data Protection Regulation, this includes any information which,
        either alone or in combination with other information we process about
        you, identifies you as an individual, including, for example, your name,
        postal address, email address and telephone number.
      </Text>

      <Heading fontSize="16px">Why do we need your personal data?</Heading>

      <Text w="100%" lineHeight={1.5} my="20px">
        We will only process your personal data in accordance with applicable
        data protection and privacy laws. We need certain personal data in order
        to provide you with access to the website. If you registered with us,
        you will have been asked to tick to agree to provide this information in
        order to access our services, purchase our products, or view our
        content. This consent provides us with the legal basis we require under
        applicable law to process your data. You maintain the right to withdraw
        such consent at any time. If you do not agree to our use of your
        personal data in line with this Policy, please do not use our website.
      </Text>
    </Box>
  );
};

export default PurposeOfProcessing;
