import {
  Button,
  CheckboxGroup,
  Flex,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { CircleCloseIcon, FilterIcon } from "../../Icons";
import FilterField from "./FilterField";

const FilterUserPopover = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom-end"
        returnFocusOnClose={false}
        p={6}
      >
        <PopoverTrigger color="primary.400">
          <Flex
            gap={2}
            alignItems="center"
            color="primary.400"
            _hover={{ cursor: "pointer" }}
          >
            <FilterIcon boxSize={5} />
            <Text fontSize="sm" fontWeight={600}>
              Filter
            </Text>
          </Flex>
        </PopoverTrigger>
        <PopoverContent p={6}>
          <Flex justify="space-between">
            <Text fontWeight={500}>Filter by</Text>

            <CircleCloseIcon
              boxSize={5}
              onClick={onClose}
              _hover={{ cursor: "pointer" }}
            />
          </Flex>
          <Stack as="form" my={8} spacing={5}>
            <CheckboxGroup>
              <FilterField label="Goal">
                <Select>
                  <option value="option1">Select a goal</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </FilterField>
              <FilterField label="Years of Experience">
                <Input type="number" />
              </FilterField>
              <FilterField label="Location">
                <Flex gap={2}>
                  <Input placeholder="Country" />
                  <Input placeholder="City" />
                </Flex>
              </FilterField>
              <FilterField label="Company">
                <Input />
              </FilterField>
              <FilterField label="First Name">
                <Input />
              </FilterField>
              <FilterField label="Last Name">
                <Input />
              </FilterField>
            </CheckboxGroup>
          </Stack>

          <Flex gap={4} my={4}>
            <Button variant="outline" color="grey.500" onClick={onClose}>
              Cancel
            </Button>
            <Button color="white" bgColor="primary.400">
              Show results
            </Button>
          </Flex>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default FilterUserPopover;
