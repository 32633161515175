import { AuthApi } from "../service/auth/AuthApi.service";
import { jwtDecode } from "jwt-decode";
import "core-js/stable/atob";
import { paths } from "../routes";

export function getUserFromToken(token) {
  const user = jwtDecode(token);
  return {
    ...user,
    isExpired: user.exp * 1000 < Date.now(),
  };
}

export function GetUserInfo() {
  const token = GetAccessToken();
  return getUserFromToken(token);
}

export function GetAccessToken() {
  return localStorage.getItem("HINTERVIEW_USER_ACCESS_TOKEN");
}

export function GetRefreshToken() {
  return localStorage.getItem("HINTERVIEW_USER_REFRESH_TOKEN");
}

export async function RefreshToken() {
  let refreshToken = GetRefreshToken();

  const { data } = await AuthApi.RefreshAuthorizationToken({
    refreshToken: refreshToken,
  });
  if (data && data.accessToken && data.refreshToken) {
    localStorage.setItem("HINTERVIEW_USER_REFRESH_TOKEN", data.refreshToken);
    localStorage.setItem("HINTERVIEW_USER_ACCESS_TOKEN", data.accessToken);
    //TODO: figure out how to refresh navbar user details without reloading the app - Info is in the token already, the refersh is to show te updated info. Possible solution is to use a central state management tool like redux
    window.location.reload();
  } else {
    localStorage.removeItem("HINTERVIEW_USER_ACCESS_TOKEN");
    localStorage.removeItem("HINTERVIEW_USER_REFRESH_TOKEN");
    localStorage.setItem("prevLocation", window.location.pathname);
    window.location.href = paths.LOGIN;
  }
}

export function injectAuthorizationInterceptor(instance) {
  instance.axiosInstance.interceptors.request.use(async (config) => {
    let accessToken = GetAccessToken();
    let refreshToken = GetRefreshToken();
    if (!accessToken || !refreshToken) return config;

    if (config.url === "/refresh") {
      config.headers.Authorization = "Bearer " + refreshToken;
      return config;
    }

    const { isExpired } = getUserFromToken(accessToken || "");
    isExpired && RefreshToken();

    config.headers.Authorization = "Bearer " + accessToken;
    config.headers["Access-Control-Allow-Credentials"] = true;
    config.withCredentials = true;
    return config;
  });
}

export async function googleAuth(credential) {
  const { pathname } = window.location;
  const { data, error } = await AuthApi.GoogleAuth({ accessToken: credential });
  if (data) {
    localStorage.setItem("HINTERVIEW_USER_REFRESH_TOKEN", data.refreshToken);
    localStorage.setItem("HINTERVIEW_USER_ACCESS_TOKEN", data.accessToken);
    if (pathname === "/signup") {
      window.location.href = paths.COMPLETE_PROFILE;
    } else {
      window.location.href = paths.FOLLOWING;
    }
  }
  return { data, error };
}

export async function logout() {
  localStorage.removeItem("HINTERVIEW_USER_ACCESS_TOKEN");
  localStorage.removeItem("HINTERVIEW_USER_REFRESH_TOKEN");
  window.location.href = paths.HOME;
}

export const isLive = () => {
  return (
    window.location.href === "https://hinterviews.com/" ||
    window.location.href === "http://hinterviews.com/"
  );
};
