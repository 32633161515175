import React from "react";
import { Box, ListItem, UnorderedList, VStack, Flex } from "@chakra-ui/react";

const SideMenu = ({ menuData, setSelected, selected }) => {
  return (
    <Box
      display={{ base: "none", lg: "block" }}
      position={{ lg: "fixed" }}
      top={0}
      bottom={0}
      className="bg-lavendar"
      minW="292px"
      maxW="305px"
      w="100%"
      py="30px"
      marginTop={{ xl: "40px" }}
    >
      <VStack
        w="100%"
        mx="auto"
        spacing={3}
        alignItems="flex-start"
        mt="30px"
        padding={6}
      >
        <UnorderedList styleType="none" spacing={8} mx={0} w="100%">
          {menuData.map((menuItem, i) => (
            <ListItem
              id={menuItem.hash}
              fontSize="14px"
              color="#0D5EBA"
              fontWeight={600}
              key={i}
              display="flex"
              alignItems="center"
              gap="10px"
              cursor="pointer"
              onClick={() => setSelected(menuItem.hash)}
              opacity={selected === menuItem.hash ? 1 : 0.5}
              w="100%"
              lineHeight="22.4px"
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                w="24px"
                h="24px"
                borderRadius="50%"
                color="white"
                bgColor="#0D5EBA"
              >
                {i + 1}
              </Flex>
              {menuItem.title}
            </ListItem>
          ))}
        </UnorderedList>
      </VStack>
    </Box>
  );
};

export default SideMenu;
