import HttpService from "..";
import { injectAuthorizationInterceptor } from "../../utils";

const locationApiService = new HttpService({
  baseURL: "https://hinterview-backend-service.azurewebsites.net/api/",
});

injectAuthorizationInterceptor(locationApiService);

export const LocationApiService = {
  async GetCities() {
    return locationApiService.SendRequest({
      method: "get",
      path: "/cities",
    });
  },
  async GetCitiesByCountry(dto) {
    return locationApiService.SendRequest({
      method: "get",
      path: `/cities/${dto.id}`,
    });
  },
  async GetCountries() {
    return locationApiService.SendRequest({
      method: "get",
      path: "/countries",
    });
  },
  async GetStates() {
    return locationApiService.SendRequest({
      method: "get",
      path: "/states",
    });
  },
};
