import React from "react";
import { Box, Text } from "@chakra-ui/react";

const ContactInfo = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        We welcome your comments or questions about this Policy. You may contact
        us in writing or through our website.
      </Text>
    </Box>
  );
};

export default ContactInfo;
