import {
  Box,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  ListItem,
  UnorderedList,
  Flex,
} from "@chakra-ui/react";
import React from "react";

const MobileMenu = ({ isOpen, onClose, menuData, setSelected, selected }) => {
  return (
    <Box as="nav" bgColor="red" color="white" display={{ lg: "none" }}>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay display={{ lg: " none" }} />
        <DrawerContent display={{ lg: "none" }} bg="#E3EFFC">
          <DrawerCloseButton />
          <DrawerBody px={2}>
            <Box w="90%" mx="auto" my="30px" overflowY="auto">
              <UnorderedList
                styleType="none"
                spacing={4}
                mt="50px"
                mx={0}
                w="100%"
              >
                {menuData.map((menuItem, i) => (
                  <ListItem
                    id={menuItem.hash}
                    fontSize="14px"
                    color="#0D5EBA"
                    fontWeight={600}
                    key={i}
                    display="flex"
                    alignItems="center"
                    gap="10px"
                    cursor="pointer"
                    onClick={() => setSelected(menuItem.hash)}
                    opacity={selected === menuItem.hash ? 1 : 0.5}
                    mx={0}
                    w="100%"
                  >
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      w="24px"
                      h="24px"
                      borderRadius="50%"
                      color="white"
                      bgColor="#0D5EBA"
                    >
                      {i + 1}
                    </Flex>
                    {menuItem.title}
                  </ListItem>
                ))}
              </UnorderedList>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
