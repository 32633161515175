import { Avatar, Flex, Text } from "@chakra-ui/react";
import React from "react";

const Notification = ({ user, image, action }) => {
  return (
    <Flex gap={5} alignItems="center">
      <Avatar name={user} src={image} size="md" />
      <Flex
        w="full"
        flexDir={{ base: "column", md: "row" }}
        rowGap={3}
        justifyContent="space-between"
      >
        <Text fontSize="sm">
          <Text fontWeight="semibold" as="span">
            {user}
          </Text>{" "}
          {action === "followed" && <Text as="span">followed you</Text>}
          {action === "achievedGoal" && (
            <Text as="span">
              achieved a goal:{" "}
              <Text as="span" color="#EB5017" fontWeight="semibold">
                Land a promotion
              </Text>{" "}
            </Text>
          )}
        </Text>
        <Text fontSize="sm">1 hour ago</Text>
      </Flex>
    </Flex>
  );
};

export default Notification;
