import { Box, Heading } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import PolicyAndServiceLayout from "../../components/Layouts/PolicyAndServiceLayout";
import { termsMenuData } from "../../utils/staticData";

const TermsOfService = () => {
  const [selected, setSelected] = useState(termsMenuData[0].hash);
  const [displayData, setDisplayData] = useState({
    title: "",
    hash: "",
    data: "",
  });
  useEffect(() => {
    const findInfo = termsMenuData.find((policy) => policy.hash === selected);
    if (findInfo) {
      setDisplayData(findInfo);
    } else {
      setDisplayData({
        title: "",
        hash: "",
        data: "",
      });
    }
  }, [selected]);
  return (
    <Box maxW={{ xl: "1100px" }} marginX={{ xl: "auto" }}>
      <PolicyAndServiceLayout
        menuData={termsMenuData}
        setSelected={setSelected}
        heading="Terms of Service"
        selected={selected}
      />
      <Box
        position={{ lg: "relative" }}
        left={{ lg: "292px" }}
        width={{ lg: "calc(100% - 292px)" }}
      >
        <Box w="90%" mx="auto">
          <Box my={5} border="1px solid #034592" position="relative">
            <Heading
              fontSize={{ base: "16px", lg: "18px" }}
              pos="absolute"
              transform="translateY(-50%)"
              left="-2px"
              top="50%"
              bg="white"
              pr={5}
            >
              {displayData.title}
            </Heading>
          </Box>

          <Box>{displayData.data}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsOfService;
