import { Container, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import followers from "../../assets/images/followers.svg";

const EmptyState = ({ header, instructions }) => {
  return (
    <Container centerContent pt={{ lg: 32 }}>
      <Image src={followers} />
      <Stack
        mt={8}
        textAlign="center"
        spacing={4}
        color="black"
        fontWeight={500}
        maxW="md"
      >
        <Text>{header}</Text>
        <Text>{instructions}</Text>
      </Stack>
    </Container>
  );
};

export default EmptyState;
