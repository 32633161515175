import {
  Box,
  Flex,
  Heading,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { MenuIcon } from "../Icons";
import MobileMenu from "../PolicyAndService/MobileMenu";
import SideMenu from "../PolicyAndService/SideMenu";

const PolicyAndServiceLayout = ({
  heading = "Privacy Policy",
  menuData,
  setSelected,
  selected,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SideMenu
        menuData={menuData}
        setSelected={setSelected}
        selected={selected}
      />
      <Box
        position={{ lg: "relative" }}
        left={{ lg: "292px" }}
        width={{ lg: "calc(100% - 292px)" }}
        mt={{ base: "15px", lg: "40px" }}
      >
        <Box w="90%" mx="auto">
          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            w="100%"
            alignItems={{ lg: "center" }}
            justifyContent="space-between"
            gap={2}
            py={4}
          >
            <HStack
              w={{ base: "100%", lg: "fit-content" }}
              spacing={5}
              justifyContent="space-between"
            >
              {/* menu icon */}
              <Heading fontSize={{ base: "20px", lg: "24px" }}>
                {heading}
              </Heading>

              <Box w="fit-content" display={{ lg: "none" }}>
                <MenuIcon
                  role="menubutton"
                  aria-label="open menu button"
                  onClick={onOpen}
                />
              </Box>
            </HStack>
            <Text color="#B6B6B6" fontSize={{ base: "14px", lg: "1rem" }}>
              Updated November 2023
            </Text>
          </Flex>
          <MobileMenu
            isOpen={isOpen}
            onClose={onClose}
            menuData={menuData}
            setSelected={setSelected}
            selected={selected}
          />
          {/* <Box as="section">{children}</Box> */}
        </Box>
      </Box>
    </>
  );
};

export default PolicyAndServiceLayout;
