import React from "react";
import { Box, Text } from "@chakra-ui/react";

const Disclaimer = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        The information available via the website may include typographical
        errors or inaccuracies. The Company shall not be liable for these
        inaccuracies and errors. <br /> <br />
        The Company makes no representations about the availability, accuracy,
        reliability, suitability, and timeliness of the Content contained on and
        services available on the website. To the maximum extent allowed by the
        applicable law, all such Content and services are provided on the “as
        is” basis. The Company disclaims all warranties and conditions regarding
        this Content and services, including warranties and provisions of
        merchantability, fitness for a certain purpose.
        <br /> <br /> To the maximum extent permitted by the applicable law, in
        no event shall the Company be liable for any direct, indirect,
        incidental, consequential, special, punitive damages including, but not
        limited to, damages for loss of enjoyment, data or profits, in the
        connection with the enjoyment or execution of the website in the context
        of the inability or delay to enjoy the website or its services, or for
        any Content of the website, or otherwise arising out of the enjoyment of
        the website, based on contract and non-contract liability or other
        reason.
        <br /> <br />
        If the exclusion or limitation of liability for damages, whether
        consequential or incidental, are prohibited in a particular case, the
        exclusion or limitation of liability shall not apply to you.
      </Text>
    </Box>
  );
};

export default Disclaimer;
