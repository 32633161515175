import React from "react";
import UserProfile from "../../components/Profile/UserProfile";
import {
  Box,
  Flex,
  Card,
  Text,
  Accordion,
  Button,
  Spinner,
  Center,
} from "@chakra-ui/react";
import Goal from "../../components/Goals/Goal";
import { ArrowLeftIcon } from "../../components/Icons";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ProfileApi } from "../../service/profile/ProfileApi.service";

const User = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const goBack = () => {
    navigate(-1);
  };

  const {
    data: { data: { values: goals } } = { data: { data: { values: [] } } },
    isLoading,
    error,
  } = useQuery({
    queryKey: ["userGoals"],
    queryFn: () => ProfileApi.GetUserGoalsById({ id }),
  });

  return (
    <Box w="full" mt={-12}>
      <Box mt={{ base: 6, lg: 0 }} mb={6} w="full">
        <Button
          variant="link"
          leftIcon={<ArrowLeftIcon boxSize={4} />}
          _hover={{ textDecor: "none" }}
          onClick={goBack}
        >
          Back
        </Button>
      </Box>
      <Flex w="full" flexDir={{ base: "column", md: "row" }} gap={8}>
        <UserProfile userId={id} />
        <Box flexBasis={{ base: "100%", lg: "75%" }} w="full">
          <Card p={{ base: 3, lg: 4, xl: 10 }} w="full">
            <Text fontWeight={600}>Goal Milestones ({goals?.length})</Text>
            {isLoading && (
              <Center h="200px">
                <Spinner />
              </Center>
            )}
            {!isLoading && !error && (
              <Accordion
                display="flex"
                flexDir="column"
                rowGap={6}
                py={6}
                allowToggle
              >
                {!!goals && (
                  <>
                    {goals.length > 0 &&
                      goals
                        .filter((data) => data.status === "Achieved")
                        .map((goal) => <Goal key={goal.id} {...goal} />)}
                    {goals.length === 0 && (
                      <Center h="50px">
                        No goal milestone has been add yet
                      </Center>
                    )}
                  </>
                )}
              </Accordion>
            )}
          </Card>
        </Box>
      </Flex>
    </Box>
  );
};

export default User;
