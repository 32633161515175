import {
  Box,
  Heading,
  Text,
  Container,
  FormControl,
  FormLabel,
  Button,
  Stack,
  FormErrorMessage,
  InputGroup,
  Badge,
  Flex,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GoalsApi } from "../../service/goals/GoalsApi.service";
import { ProfileApi } from "../../service/profile/ProfileApi.service";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import ProfileForm from "../../components/Profile/ProfileForm";
import { paths } from "../../routes";

const ProfileSchema = Yup.object().shape({
  goals: Yup.array().required("Required"),
});

const CompleteProfile = () => {
  const [step, setStep] = useState(1);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [profile, setProfile] = useState();

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      goals: [],
    },
    validationSchema: ProfileSchema,
    onSubmit: () => {
      setProfile((prev) => {
        return {
          ...prev,
        };
      });

      completeProfile(profile);
    },
  });

  const { mutate: completeProfile } = useMutation({
    mutationFn: (user) =>
      ProfileApi.CreateProfile({
        ...user,
        prefferedName: user.firstName,
        ...values,
      }),
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: ({ data, error }) => {
      setLoading(false);
      if (data) {
        navigate(paths.COMMUNITY);
      } else if (error) {
        toast({
          title: error.defaultMessage,
          status: "error",
        });
      }
    },
    onError: (err) => {
      toast({
        title: err.defaultMessage
          ? err.defaultMessage
          : "There is a problem, try again",
        status: "error",
      });
    },
  });

  const selectGoal = (goal) => {
    if (!values.goals.includes(goal.id) && values.goals.length < 2) {
      setFieldValue("goals", [...values.goals, goal.id]);
      setSelectedGoals([...selectedGoals, goal]);
    } else {
      setFieldValue(
        "goals",
        values.goals.filter((g) => g !== goal.id)
      );
      const newGoals = selectedGoals.filter((g) => g.id !== goal.id);
      setSelectedGoals(newGoals);
    }
  };

  const {
    data: { data: goals } = {
      data: {
        data: [],
      },
    },
    isLoading,
  } = useQuery({
    queryKey: ["goals"],
    queryFn: () => GoalsApi.GetGoals(),
    enabled: !!(step === 2),
  });

  const handleProfileForm = (values) => {
    setStep(step + 1);
    setProfile(values);
  };

  return (
    <Box w="full" py={5} className="auth">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box as="nav" hideBelow="lg" px={6}>
            <Text fontWeight={600}>hinterview </Text>
          </Box>
          <Container px={5} centerContent minH="90vh" justifyContent="center">
            <Box w="full">
              <Stack spacing={5} mb={6}>
                <Heading fontSize="3.5xl" fontWeight={600}>
                  Complete your profile
                </Heading>
                <Text fontSize="sm" color="grey.800">
                  Let's get you started
                </Text>
              </Stack>
              {step === 1 ? (
                <ProfileForm handleButtonClick={handleProfileForm} />
              ) : (
                <>
                  <FormControl isInvalid={!!errors.goals && touched.goals}>
                    <FormLabel>
                      What is your goal? {""}
                      <Text
                        as="span"
                        color="grey.600"
                        fontSize="sm"
                        fontWeight={400}
                      >
                        (Select a maximum of two goals)
                      </Text>
                    </FormLabel>

                    <InputGroup
                      size="md"
                      borderColor="grey.300"
                      borderWidth="1px"
                      px={4}
                      py={3}
                      borderRadius="md"
                    >
                      {!!selectedGoals && (
                        <Flex gap={3}>
                          {selectedGoals.map((goal, index) => (
                            <Badge
                              key={index}
                              variant="select"
                              borderColor="primary.500"
                              bgColor="primary.50"
                            >
                              {goal.value}
                            </Badge>
                          ))}
                        </Flex>
                      )}
                    </InputGroup>
                    <FormErrorMessage>{errors.goals}</FormErrorMessage>
                  </FormControl>
                  <Box my={6} p={4} bgColor="grey.75" borderRadius="lg">
                    <Text color="grey.900" fontSize="sm" fontWeight={500}>
                      Suggested goals
                    </Text>
                    <Flex
                      my={6}
                      columnGap={{ base: 2, lg: 3 }}
                      rowGap={4}
                      wrap="wrap"
                    >
                      {goals &&
                        goals.values.map((goal, index) => (
                          <Badge
                            key={index}
                            variant="select"
                            borderColor={
                              values.goals.includes(goal.id) && "primary.500"
                            }
                            bgColor={
                              values.goals.includes(goal.id) && "primary.50"
                            }
                            onClick={() => selectGoal(goal)}
                          >
                            {goal.value}
                          </Badge>
                        ))}
                    </Flex>
                  </Box>
                  <Button
                    type="submit"
                    variant="brandPrimary"
                    isDisabled={!!(values.goals.length === 0)}
                    isLoading={loading}
                    onClick={handleSubmit}
                  >
                    Continue
                  </Button>
                </>
              )}
            </Box>
          </Container>
        </>
      )}
    </Box>
  );
};

export default CompleteProfile;
