import {
  Avatar,
  Badge,
  Box,
  Card,
  Flex,
  Icon,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FollowIcon, StarIcon, UnfollowOutlineIcon } from "../Icons";
import { NavLink as ReactRouterLink, useLocation } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { FollowApi } from "../../service/follow/FollowApi.service";
import { paths } from "../../routes";

const FollowerCard = ({
  userId,
  firstName,
  lastName,
  avatar,
  job,
  followedBack,
  organization,
  city,
  country,
  upvoteCount,
  achievedGoal,
  profileId,
}) => {
  const { pathname } = useLocation();
  const [followAction, setFollowAction] = useState(followedBack);
  const { mutate: follow, isPending } = useMutation({
    mutationFn: async () =>
      await FollowApi.FollowUser({
        profileId: profileId,
        action: followAction ? 0 : 1,
      }),
    onSuccess: ({ data }) => {
      console.log(data);
      if (data) {
        setFollowAction(!followAction);
      }
    },
  });

  return (
    <>
      <Card p={4} minW={{ md: "320px" }} h="max-content" pos="relative">
        <ChakraLink
          as={ReactRouterLink}
          to={`/u/${userId}`}
          _hover={{ textDecoration: "none" }}
        >
          <Box maxW="95%">
            <Flex justify="space-between">
              <Flex gap={3} alignItems="center">
                <Avatar src={avatar} name={`${firstName} ${lastName}`} />
                <Box>
                  <Text fontSize="xl" fontWeight={600} color="primary.400">
                    {firstName} {lastName}
                  </Text>
                  <Text color="grey.600" fontSize="sm" fontWeight={500}>
                    {job}
                  </Text>
                </Box>
              </Flex>
              <Text
                display="flex"
                alignItems="center"
                gap={1}
                color="green.400"
              >
                <Icon as={StarIcon} />
                {upvoteCount}
              </Text>
            </Flex>
            <Flex alignItems="center" w="full" py={3}>
              <Stack spacing={2} fontSize="sm" color="gray.700">
                <Text fontWeight={500}>{organization}</Text>
                <Text fontWeight={400}>
                  {city}, {country}
                </Text>
              </Stack>
              <Spacer />
            </Flex>
            <Badge variant="achieved">
              Achieved goal:{" "}
              <Text as="span" textTransform="capitalize">
                {achievedGoal}
              </Text>
            </Badge>
          </Box>
        </ChakraLink>
        {pathname !== paths.FOLLOWING && (
          <Text
            display="flex"
            alignItems="center"
            gap={1}
            color="primary.500"
            fontSize="sm"
            fontWeight={600}
            onClick={follow}
            zIndex="overlay"
            pos="absolute"
            right={8}
            bottom={16}
            _hover={{ cursor: "pointer" }}
          >
            {isPending ? (
              <Spinner />
            ) : (
              <>
                <Icon
                  as={followedBack ? UnfollowOutlineIcon : FollowIcon}
                  color="primary.600"
                />
                {followAction ? "Unfollow" : "Follow"}
              </>
            )}
          </Text>
        )}
      </Card>
    </>
  );
};

export default FollowerCard;
