import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import Oauth from "../../../components/Auth/Oauth";
import * as Yup from "yup";
import PasswordInput from "../../../components/Auth/PasswordInput";
import { useAuthContext } from "../../../context/Auth";
import { paths } from "../../../routes";

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  confirmPassword: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
  email: Yup.string().email("Invalid email").required("Required"),
  terms: Yup.bool().oneOf([true], "Field must be checked"),
  privacyPolicy: Yup.bool().oneOf([true], "Field must be checked"),
});

const Signup = () => {
  const { handleSignup } = useAuthContext();
  const toast = useToast();
  const {
    getFieldProps,
    errors,
    touched,
    handleSubmit,
    isValid,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      privacyPolicy: false,
      terms: false,
    },
    onSubmit: async (values) => {
      const { error, data } = await handleSignup({
        emailAddress: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
      });
      if (data) {
        toast({
          title: "Welcome!",
          status: "success",
        });
      } else {
        toast({
          title: error.defaultMessage,
          status: "error",
        });
      }
    },
    validationSchema: SignupSchema,
  });

  return (
    <Box h="max-content" w="full">
      <Heading fontSize="3.5xl" mb={2} fontWeight={600}>
        Sign Up!
      </Heading>
      <Text fontSize="sm" color="office-brown.700">
        Already have an account ?{" "}
        <Link to={paths.LOGIN} className="text-primary font-semibold">
          Login
        </Link>
      </Text>
      <Box as="form" my={6} onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.email && touched.email}>
            <FormLabel htmlFor="email">Email Address</FormLabel>
            <Input
              variant="outline"
              type="email"
              id="email"
              {...getFieldProps("email")}
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password && touched.password}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <PasswordInput id="password" {...getFieldProps("password")} />
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors.confirmPassword && touched.confirmPassword}
          >
            <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
            <PasswordInput
              id="confirmPassword"
              {...getFieldProps("confirmPassword")}
            />
            <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack className="text-xs font-medium tracking-[-0.06px]">
          <Checkbox
            size="xl"
            colorScheme="blue"
            pt={6}
            pb={3}
            {...getFieldProps("terms")}
            data-testid="terms"
          >
            I agree to Hinterview's{" "}
            <a href="/" className="text-primary">
              Terms & Conditions
            </a>
          </Checkbox>
          <Checkbox
            size="xl"
            colorScheme="blue"
            {...getFieldProps("privacyPolicy")}
            data-testid="privacyPolicy"
          >
            I understand that Hinterview will process my information in
            accordance with their{" "}
            <a href="/" className="text-primary">
              Privacy Policy
            </a>
          </Checkbox>
        </Stack>
        <Button
          type="submit"
          variant="brandPrimary"
          my={8}
          isDisabled={!(dirty && isValid)}
          isLoading={isSubmitting}
        >
          Sign up
        </Button>
        <Oauth />
      </Box>
    </Box>
  );
};

export default Signup;
