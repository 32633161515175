import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoWarningOutline } from "react-icons/io5";

const AccountSetting = () => {
  return (
    <Card w="full" maxW="3xl" px={{ base: 2, md: 5 }} py={6}>
      <CardHeader>
        <Box>
          <Heading as="h6" size="sm">
            Account Setting
          </Heading>
          <Text mt={5} fontSize="sm">
            Update your username and delete your account here.{" "}
          </Text>
        </Box>
      </CardHeader>
      <CardBody>
        <Stack spacing={10}>
          <Box>
            <Text fontSize="sm" fontWeight="semibold">
              Username
            </Text>
            <Flex w="full" gap={2} alignItems="center">
              <Input />
              <Button variant="brandPrimary" w="fit-content" py={2}>
                Update username
              </Button>
            </Flex>
            <Text fontSize="xs" mt={2}>
              Your profile link will look like this hinterviews.com/@username
            </Text>
          </Box>
          <Box>
            <Text>Delete Account</Text>
            <Flex alignItems="center" gap={20} my={6}>
              <Text>Click to delete account</Text>
              <Button
                rightIcon={<AiFillDelete color="red" />}
                variant="outline"
                color="red"
              >
                Delete
              </Button>
            </Flex>
            <Text display="flex" alignItems="center" fontSize="sm" gap={1}>
              <Text
                as="span"
                color="red"
                display="flex"
                alignItems="center"
                gap={2}
              >
                <IoWarningOutline />
                Note:
              </Text>{" "}
              By deleting your account you lose everything saved on this
              account.
            </Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default AccountSetting;
