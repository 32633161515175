import React from "react";
import {
  Stack,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Text,
  Hide,
  Center,
} from "@chakra-ui/react";
import FollowerCard from "../../components/Followers/FollowerCard";
import { SearchIcon } from "../../components/Icons";
import FilterUserModal from "../Modals/FilterUserModal";
import Skeleton from "../Skeleton/Skeleton";
import FollowerCardSkeleton from "../Skeleton/FollowerCardSkeleton";

const UserList = ({ users, emptyState, title, isLoading, error }) => {
  return (
    <>
      {isLoading && (
        <Skeleton skeletonItem={<FollowerCardSkeleton />} number={4} />
      )}
      {!isLoading && !error && (
        <Flex flexDir="column">
          <Hide above="lg">
            <Text color="grey.800" fontWeight={600} mb={6}>
              {title}
            </Text>
          </Hide>
          {!!users && users?.length > 0 ? (
            <Stack w="full" spacing={8}>
              <Flex justify="space-between" alignItems="center">
                <InputGroup w={{ base: "60%", lg: "auto" }} maxW="sm">
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    fontSize="lg"
                  >
                    <Icon as={SearchIcon} />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    px={{ lg: 20 }}
                    borderRadius="3xl"
                  />
                </InputGroup>
                <FilterUserModal />
              </Flex>
              <Grid
                autoRows="max-content"
                templateColumns={{
                  xl: "repeat(2,1fr)",
                }}
                columnGap={4}
                rowGap={6}
                w="full"
              >
                {users.map((data) => (
                  <GridItem key={data.userId}>
                    <FollowerCard {...data} />
                  </GridItem>
                ))}
              </Grid>
            </Stack>
          ) : (
            emptyState && emptyState
          )}
        </Flex>
      )}
      {!isLoading && error && (
        <Center height="50px">
          <Text> Something is wrong, Could not get Following</Text>
        </Center>
      )}
    </>
  );
};

export default UserList;
