import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProfileApi } from "../../../service/profile/ProfileApi.service";
import ProfileForm from "../../Profile/ProfileForm";
import { RefreshToken } from "../../../utils";

const EditProfileModal = ({ isOpen, onClose }) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate: editProfile, isPending } = useMutation({
    mutationFn: (user) =>
      ProfileApi.UpdateProfile({
        ...user,
      }),
    onSuccess: ({ data, error }) => {
      if (data) {
        toast({
          title: "Profile Edit Successfully",
          status: "success",
        });
        RefreshToken();
        queryClient.invalidateQueries({
          queryKey: ["profile"],
          refetchType: "all",
        });
        onClose();
      } else if (error) {
        toast({
          title: error.defaultMessage,
          status: "error",
        });
      }
    },
  });

  const handleButtonClick = (values) => {
    editProfile(values);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} py={{ base: 6, lg: 10 }}>
      <ModalOverlay />
      <ModalContent py={{ base: 6, lg: 10 }}>
        <ModalHeader>Edit profile details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProfileForm
            isEdit
            isLoading={isPending}
            handleButtonClick={handleButtonClick}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditProfileModal;
