import React from "react";
import {
  Box,
  Input,
  FormControl,
  InputGroup,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import { useAutoComplete } from "./useAutoComplete";

const AutoComplete = ({
  options: suggestions,
  handleClick,
  value: input,
  name,
}) => {
  const {
    value,
    handleInputChange,
    options,
    activeOption,
    showOptions,
    selectOption,
  } = useAutoComplete({
    suggestions,
    handleClick,
    input,
  });

  const suggestionsList = () => {
    return (
      <Box
        maxH="100px"
        bgColor="gray.100"
        borderBottomRadius="md"
        border="1px solid #ccc"
        borderTop="none"
        pos="absolute"
        w="full"
        zIndex="banner"
        overflowY="scroll"
      >
        <Stack spacing={1} as="ul" py={2} fontSize="md">
          {options.length ? (
            <>
              {options.map((option, index) => {
                let color = "transparent";
                if (index === activeOption) {
                  color = "white";
                }
                return (
                  <Box
                    as="li"
                    key={option.key ?? option.id}
                    onClick={() => selectOption(index)}
                    bgColor={color}
                    px={2}
                    py={2}
                    _hover={{
                      cursor: "pointer",
                    }}
                  >
                    {option.value}
                    <StackDivider />
                  </Box>
                );
              })}
            </>
          ) : (
            <Box px={2}>
              <em>No suggestions available.</em>
            </Box>
          )}
        </Stack>
      </Box>
    );
  };

  return (
    <FormControl pos="relative">
      <InputGroup>
        <Input
          value={value}
          onChange={handleInputChange}
          name={name}
          autoComplete="off"
        />
      </InputGroup>
      {showOptions && suggestionsList()}
    </FormControl>
  );
};

export default AutoComplete;
