import {
  defineStyleConfig,
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/react";
import { checkboxAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const sizes = {
  xl: definePartsStyle({
    control: defineStyle({
      boxSize: 18,
    }),
    label: defineStyle({
      fontSize: "12px",
      marginLeft: 3,
    }),
  }),
};

const checkboxBaseStyle = definePartsStyle({
  control: {
    borderColor: "black",
    borderRadius: 4,
  },
});

const brandPrimary = defineStyle({
  background: "primary.400",
  color: "white",
  fontWeight: "600",
  _disabled: {
    background: "grey.300",
    _hover: {
      background: "grey.400",
      opacity: "0.4",
    },
  },
  _hover: {
    backgroundColor: "transparent",
    color: "primary.400",
    border: "1px solid",
    borderColor: "primary.400",
    transitionDuration: "0.5s",
    transitionTimingFunction: "ease",
    _disabled: {
      background: "grey.400",
      opacity: "0.4",
    },
  },
  px: 6,
  py: 6,
  borderRadius: "lg",
  w: "full",
});

const brandLink = defineStyle({
  fontWeight: 500,
  _hover: {
    textDecoration: "none",
    borderBottom: 4,
    borderColor: "primary.500",
    borderStyle: "solid",
    paddingBottom: 1.5,
  },
});

const stepperBaseStyle = {
  indicator: {
    // change the default border radius to 0
    borderRadius: 0,
  },
  separator: {
    background: "green",
  },
};

export const stepperTheme = {
  stepperBaseStyle,
};

export const buttonTheme = defineStyleConfig({
  variants: { brandPrimary },
});

export const linkTheme = defineStyleConfig({
  variants: { brandLink },
});

export const checkboxTheme = defineMultiStyleConfig({
  sizes,
  baseStyle: checkboxBaseStyle,
});
