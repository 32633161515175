import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "../../../service/auth/AuthApi.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordInput from "../../../components/Auth/PasswordInput";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "../../../routes";

const ChangePassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get("resetToken");
  const navigate = useNavigate();
  const toast = useToast();
  const { mutate: changePassword } = useMutation({
    mutationFn: async (user) =>
      await AuthApi.ChangePassword({ resetToken, ...user }),
    onSuccess: ({ data, error }) => {
      if (data) {
        toast({
          title: "Password Reset Successful",
          status: "success",
        });
        navigate(paths.LOGIN);
      } else if (error) {
        toast({
          title: error.defaultMessage,
          status: "error",
        });
      }
    },
    onError: (err) => {
      toast({
        title: err.defaultMessage
          ? err.defaultMessage
          : "There is a problem, try again",
        status: "error",
      });
    },
  });

  const {
    getFieldProps,
    errors,
    touched,
    handleSubmit,
    isValid,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      changePassword(values);
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .min(8, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      confirmPassword: Yup.string()
        .min(8, "Too Short!")
        .max(50, "Too Long!")
        .required("Required")
        .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
    }),
  });
  return (
    <Box w="full">
      <form onSubmit={handleSubmit}>
        <Heading fontWeight={500} fontSize="3.5xl" mb={8} role="heading">
          Change Password
        </Heading>
        <FormControl isInvalid={!!errors.newPassword && touched.newPassword}>
          <FormLabel htmlFor="newPassword">New Password</FormLabel>
          <PasswordInput {...getFieldProps("newPassword")} id="newPassword" />
          <FormErrorMessage>{errors.newPassword}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={!!errors.confirmPassword && touched.confirmPassword}
          mt={2}
        >
          <FormLabel htmlFor="confirmPassword">Confirm New Password</FormLabel>
          <PasswordInput
            {...getFieldProps("confirmPassword")}
            id="confirmPassword"
          />
          <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          variant="brandPrimary"
          my={8}
          isLoading={isSubmitting}
          isDisabled={!(dirty && isValid)}
        >
          Continue
        </Button>
      </form>
    </Box>
  );
};

export default ChangePassword;
