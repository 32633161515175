import { useEffect, useState } from "react";

export const useAutoComplete = ({ suggestions, handleClick, input }) => {
  const [inputValue, setInputValue] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [activeOption, setActiveOption] = useState(-1);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setShowOptions(true);
    const filteredArr = suggestions.filter(
      (suggestion) =>
        suggestion.value.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    setFilteredSuggestions(filteredArr);
  };
  const selectOption = (index) => {
    setInputValue(filteredSuggestions[index].value);
    handleClick(
      filteredSuggestions[index].id ?? filteredSuggestions[index].key
    );
    setActiveOption(index);
    setFilteredSuggestions(suggestions);
    setShowOptions(false);
  };

  useEffect(() => {
    if (!!input && suggestions) {
      const option = suggestions?.filter(
        (suggestion) =>
          (suggestion.id ? Number(suggestion.id) : Number(suggestion.key)) ===
          Number(input)
      );
      option?.length > 0 && setInputValue(option[0].value);
    }
  }, [input, suggestions]);
  return {
    suggestions,
    handleInputChange,
    value: inputValue,
    options: filteredSuggestions,
    activeOption,
    showOptions,
    selectOption,
  };
};
