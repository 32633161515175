import { Box, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import React from "react";

const FollowerCardSkeleton = () => {
  return (
    <Box
      padding="6"
      boxShadow="lg"
      bg="white"
      minW={{ md: "320px" }}
      h="max-content"
    >
      <SkeletonCircle size="10" />
      <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
    </Box>
  );
};

export default FollowerCardSkeleton;
