import {
  Card,
  CardBody,
  Stack,
  StackDivider,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import Post from "../../components/Community/Post";
import { posts } from "../../mockDB";
import { GetUserInfo } from "../../utils";

const Community = () => {
  const user = GetUserInfo();
  return (
    <Card w="full">
      <CardBody>
        <Stack divider={<StackDivider />} spacing={6}>
          <Stack spacing={3} mb={2}>
            <Text fontWeight="medium">
              Post as {`${user.fname} ${user.lname}`}
            </Text>
            <Textarea
              placeholder="Share something with your network"
              pt={8}
              pb={4}
              _placeholder={{
                fontSize: "12px",
                color: "#98A2B3",
              }}
            />
          </Stack>
          {posts && posts.map((post) => <Post key={post.id} {...post} />)}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default Community;
