import HttpService from "..";

const organizationService = new HttpService({
  baseURL: "https://hinterview-backend-service.azurewebsites.net/api/",
});

export const OrganizationApi = {
  async GetCompanies() {
    return organizationService.SendRequest({
      method: "get",
      path: "/companies",
    });
  },

  async GetSchools() {
    return organizationService.SendRequest({
      method: "get",
      path: "/schools",
    });
  },
};
