import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { theme } from "./styles/theme";
import { Routes } from "./routes";
import { useAuthContext } from "./context/Auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  const { isAuthorized } = useAuthContext();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId="601310669000-q925b83l9t10lioh0e7j0jjp3jh3nv6f.apps.googleusercontent.com">
        <ChakraProvider
          theme={theme}
          toastOptions={{
            defaultOptions: {
              position: "top-right",
              variant: "subtle",
              isClosable: true,
              duration: 3000,
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            <Routes isAuthorized={isAuthorized} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ChakraProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
}

export default App;
