import HttpService from "..";

const authApiService = new HttpService({
  baseURL: "https://hinterview-backend-service.azurewebsites.net/api/",
});

export const AuthApi = {
  async RefreshAuthorizationToken(dto) {
    return authApiService.SendRequest({
      method: "post",
      path: "/authenticate/refresh-token",
      body: dto,
    });
  },

  async Signup(dto) {
    return authApiService.SendRequest({
      method: "post",
      path: "/user/register",
      body: dto,
    });
  },

  async VerifyAccount({ userId, ...dto }) {
    return authApiService.SendRequest({
      method: "post",
      path: `/user/activate/${userId}`,
      body: dto,
    });
  },

  async Login(dto) {
    return authApiService.SendRequest({
      method: "post",
      path: "/authenticate/login",
      body: dto,
    });
  },

  async RefreshToken(dto) {
    return authApiService.SendRequest({
      method: "post",
      path: "/authenticate/refresh-token",
      body: dto,
    });
  },

  async ResetPassword(dto) {
    return authApiService.SendRequest({
      method: "post",
      path: "/authenticate/reset-password",
      body: dto,
    });
  },

  async ChangePassword(dto) {
    return authApiService.SendRequest({
      method: "post",
      path: "/authenticate/change-password ",
      body: dto,
    });
  },

  async GoogleAuth(dto) {
    return authApiService.SendRequest({
      method: "get",
      path: `/socials/google/sign-in?access_token=${dto.accessToken}`,
    });
  },

  async LinkedInAuth(dto) {
    return authApiService.SendRequest({
      method: "get",
      path: `/socials/linkedIn/sign-in?access_token=${dto.accessToken}`,
    });
  },

  async MicrosoftAuth(dto) {
    return authApiService.SendRequest({
      method: "get",
      path: `/socials/microsoft/sign-in?access_token=${dto.accessToken}`,
    });
  },

  async ResendRegistrationToken() {
    return authApiService.SendRequest({
      method: "post",
      path: "",
    });
  },
};
