import React from "react";
import { Box, Text } from "@chakra-ui/react";

const InternationalDataTransfer = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        Your information, including personal data that we collect from you, may
        be transferred to, stored at and processed by us outside the country in
        which you reside, where data protection and privacy regulations may not
        offer the same level of protection as in other parts of the world. By
        accepting this Policy, you agree to this transfer, storing or
        processing. We will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this Policy.
      </Text>
    </Box>
  );
};

export default InternationalDataTransfer;
