import {
  Accordion,
  Box,
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Goal from "../../components/Goals/Goal";
import {
  CheckIcon,
  InfoCircleIcon,
  PlusCircleIcon,
  PlusIcon,
} from "../../components/Icons";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ProfileApi } from "../../service/profile/ProfileApi.service";
import AddNewGoalsModal from "../../components/Modals/AddNewGoalsModal";
import AchieveGoalModal from "../../components/Modals/AchieveGoalModal";
import DeleteModal from "../../components/Modals/DeleteModal";

const Profile = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isAchieveGoalModalOpen,
    onClose: onCloseAchieveGoalModal,
    onOpen: onOpenAchieveGoalModal,
  } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onClose: onCloseDeleteModal,
    onOpen: onOpenDeleteModal,
  } = useDisclosure();
  const [selectedGoaltoAchieve, setSelectedGoaltoAchieve] = useState();
  const [selectedGoaltoDelete, setSelectedGoaltoDelete] = useState();

  const queryClient = useQueryClient();
  const {
    data: { data: { values: goals } } = { data: { data: { values: [] } } },
    isLoading,
    error,
  } = useQuery({
    queryKey: ["userGoals"],
    queryFn: () => ProfileApi.GetUserGoals(),
  });

  const handleDeleteModal = (id) => {
    onOpenDeleteModal();
    setSelectedGoaltoDelete(id);
  };

  const { mutate: deleteGoal, isPending: deleteGoalLoading } = useMutation({
    mutationFn: async (id) => await ProfileApi.DeleteUserGoal({ goalId: id }),
    onSuccess: () => {
      onCloseDeleteModal();
      setSelectedGoaltoDelete("");
      queryClient.invalidateQueries({
        queryKey: ["userGoals"],
      });
    },
  });

  const handleAchieveModal = (id) => {
    onOpenAchieveGoalModal();
    setSelectedGoaltoAchieve(id);
  };

  return (
    <>
      <Box w="full">
        <Card px={{ base: 4, sm: 6 }} py={{ base: 5, sm: 6 }} minH="205px">
          <Flex justifyContent="space-between" alignItems="center" mb={9}>
            <Flex alignItems="center" gap={2}>
              <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                Current goals
              </Text>
              <Tooltip
                label="You can have a maximum of two goals"
                placement="top-end"
              >
                <InfoCircleIcon color="#1671D9" h={4} w={4} />
              </Tooltip>
            </Flex>
            <Button
              leftIcon={<PlusIcon />}
              fontSize="sm"
              py={1}
              onClick={() => onOpen()}
              variant="brandPrimary"
              w="fit-content"
              isDisabled={
                !!goals &&
                goals.filter((data) => data.status === "Active").length === 2
              }
            >
              Add new goal
            </Button>
          </Flex>
          {!error && isLoading && (
            <Center h="75px">
              <Spinner />
            </Center>
          )}
          {!isLoading && !error && (
            <Flex flexDir="column" gap={{ base: 6, md: 4 }} w="full">
              {goals &&
                goals.filter((data) => data.status === "Active") &&
                goals
                  .filter((data) => data.status === "Active")
                  .map((goal) => (
                    <Flex
                      key={goal.id}
                      flexDir={{ base: "column", md: "row" }}
                      justifyContent="space-between"
                      alignItems="center"
                      w="full"
                      gap={{ base: 4, sm: 0 }}
                    >
                      <Flex
                        px={4}
                        py={2}
                        borderRadius="xl"
                        bgColor="#F9FAFB"
                        alignItems="center"
                        gap={4}
                        h="auto"
                        w={{ base: "full", sm: "auto" }}
                        justifyContent={{
                          base: "space-between",
                          md: "flex-start",
                        }}
                      >
                        <Box
                          h={2}
                          w={2}
                          bgColor="#475367"
                          borderRadius="full"
                        ></Box>
                        <Text fontSize="sm" fontWeight="medium">
                          {goal.value}
                        </Text>
                        <Box height="20px">
                          <Divider orientation="vertical" bgColor="#98A2B3" />
                        </Box>
                        <Button
                          variant="link"
                          fontSize="xs"
                          color="#DD524D"
                          fontWeight="medium"
                          onClick={() => handleDeleteModal(goal.id)}
                        >
                          {deleteGoalLoading ? <Spinner /> : "Delete"}
                        </Button>
                      </Flex>

                      <Button
                        variant="link"
                        color="#0D5EBA"
                        leftIcon={<CheckIcon />}
                        w={{ base: "full", sm: "auto" }}
                        justifyContent={{ base: "flex-start" }}
                        fontSize="sm"
                        onClick={() => handleAchieveModal(goal.id)}
                      >
                        Mark as achieved
                      </Button>
                    </Flex>
                  ))}
              {goals && goals.length === 0 && (
                <Center h="75px">No current goal added</Center>
              )}
            </Flex>
          )}
        </Card>

        <Card mt={5}>
          <Card p={{ base: 3, lg: 4, xl: 10 }} w="full">
            <Flex justifyContent="space-between">
              <Text fontWeight={600} fontSize={{ base: "sm", lg: "md" }}>
                Goal Milestones (
                {goals?.filter((data) => data.status === "Achieved")?.length})
              </Text>
              <Flex gap={4} alignItems="center">
                <PlusCircleIcon
                  color="#1671D9"
                  w={{ base: 7, md: 8 }}
                  h={{ base: 7, md: 8 }}
                />
              </Flex>
            </Flex>
            <Accordion
              display="flex"
              flexDir="column"
              rowGap={6}
              py={6}
              allowToggle
            >
              {!!goals && (
                <>
                  {goals.length > 0 &&
                    goals
                      .filter((data) => data.status === "Achieved")
                      .map((goal) => (
                        <Goal
                          key={goal.id}
                          {...goal}
                          handleEdit={handleAchieveModal}
                        />
                      ))}
                  {goals.length === 0 && (
                    <Center h="50px">No goal milestone has been add yet</Center>
                  )}
                </>
              )}
            </Accordion>
          </Card>
        </Card>
      </Box>
      <AddNewGoalsModal
        isOpen={isOpen}
        onClose={onClose}
        activeGoals={
          !!goals && goals.filter((data) => data.status === "Active")
        }
      />
      {isAchieveGoalModalOpen && (
        <AchieveGoalModal
          isOpen={isAchieveGoalModalOpen}
          onClose={onCloseAchieveGoalModal}
          goalId={selectedGoaltoAchieve}
        />
      )}

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={onCloseDeleteModal}
        handleDelete={() => deleteGoal(selectedGoaltoDelete)}
      />
    </>
  );
};

export default Profile;
