import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import PasswordInput from "../../Auth/PasswordInput";

const LoginModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ md: "lg" }}>
      <ModalOverlay />
      <ModalContent borderRadius="3xl" p={{ base: 4, md: 10 }}>
        <ModalHeader>Login!</ModalHeader>
        <ModalBody>
          <Stack spacing={6}>
            <FormControl>
              <FormLabel>Email Address</FormLabel>
              <Input />
            </FormControl>
            <FormControl>
              <FormLabel>Current Password</FormLabel>
              <PasswordInput />
            </FormControl>
            <Button variant="brandPrimary" mt={4}>
              Continue
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
