import HttpService from "..";
import { injectAuthorizationInterceptor } from "../../utils";

const profileApiService = new HttpService({
  baseURL: "https://hinterview-backend-service.azurewebsites.net/api/profile",
});
injectAuthorizationInterceptor(profileApiService);

export const ProfileApi = {
  async CreateProfile(dto) {
    return profileApiService.SendRequest({
      method: "post",
      path: "/create",
      body: dto,
    });
  },

  async GetProfileById(dto) {
    return profileApiService.SendRequest({
      method: "get",
      path: `/get/${dto.userId}`,
    });
  },

  async GetProfile() {
    return profileApiService.SendRequest({
      method: "get",
      path: `/get`,
    });
  },

  async GetLoggedInUserProfile() {
    return profileApiService.SendRequest({
      method: "get",
      path: "/get",
    });
  },

  async UpdateProfile(dto) {
    return profileApiService.SendRequest({
      method: "post",
      path: "/update-profile",
      body: dto,
    });
  },

  async GetUpvoteCount() {
    return profileApiService.SendRequest({
      method: "get",
      path: "/upvoteCount",
    });
  },

  async GetUserGoals() {
    return profileApiService.SendRequest({
      method: "get",
      path: "/user-goals",
    });
  },

  async GetUserGoalsById(dto) {
    return profileApiService.SendRequest({
      method: "get",
      path: `/user-goals/${dto.id}`,
    });
  },

  async UpdateEmail() {
    return profileApiService.SendRequest({
      method: "get",
      path: "/updateEmail",
    });
  },

  async GetProfilesByGoals() {
    return profileApiService.SendRequest({
      method: "get",
      path: "/profiles-by-goals",
    });
  },

  async DeleteUserGoal(dto) {
    return profileApiService.SendRequest({
      method: "post",
      path: `/delete-user-goal/${dto.goalId}`,
    });
  },

  async CreateUserGoal(dto) {
    return profileApiService.SendRequest({
      method: "post",
      path: "/create-user-goals",
      body: dto,
    });
  },

  async AchieveUserGoal(dto) {
    return profileApiService.SendRequest({
      method: "post",
      path: `/achieve-user-goal`,
      body: dto,
    });
  },

  async UpdateUserGoal(dto) {
    return profileApiService.SendRequest({
      method: "post",
      path: `/update-user-goals`,
      body: dto,
    });
  },

  async UpvoteUserGoal(dto) {
    return profileApiService.SendRequest({
      method: "post",
      path: `/upvote-user-goal/${dto.goalId}`,
    });
  },

  async GetGoalStepsById(dto) {
    return profileApiService.SendRequest({
      method: "get",
      path: `/user-goal-steps/${dto.userGoalId}`,
    });
  },

  async GetUserGoalById(dto) {
    return profileApiService.SendRequest({
      method: "get",
      path: `/user-goal/${dto.id}`,
    });
  },
};
