import { Container, Flex, Stack, Hide, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Nav from "../Nav";
import SettingSideBar from "../Settings/SettingSideBar";
import { Outlet } from "react-router-dom";
import { ArrowLeftIcon } from "../Icons";

const SettingsLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container maxW="8xl" px={{ base: 4, xl: 12 }} py={{ base: 2, lg: 6 }}>
      <Nav />
      <Flex w="full" mt={{ lg: 16 }} gap={{ md: 8 }}>
        <SettingSideBar isOpen={isOpen} setIsOpen={setIsOpen} />
        <Stack hideBelow={isOpen ? "md" : ""}>
          <Hide above="md">
            <Text onClick={() => setIsOpen(true)} fontSize="xs">
              <ArrowLeftIcon /> Go Back
            </Text>
          </Hide>
          <Outlet context={[isOpen, setIsOpen]} />
        </Stack>
      </Flex>
    </Container>
  );
};

export default SettingsLayout;
