import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  Stack,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ProfileApi } from "../../service/profile/ProfileApi.service";
import Loader from "../../components/Loader";
import { LocationApiService } from "../../service/location/LocationApi.service";
import { JobsApiService } from "../../service/jobs/JobsApi.service";
import { OrganizationApi } from "../../service/organization/OrganizationApi.service";

import AutoComplete from "../../components/AutoComplete";

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  countryId: Yup.string().required("Required"),
  cityId: Yup.string().required("Required"),
  jobId: Yup.string(),
  isStudent: Yup.boolean(),
  organizationId: Yup.string(),
});

const ProfileForm = ({ isLoading, isEdit, handleButtonClick }) => {
  const queryClient = useQueryClient();
  const [profileDetails, setProfileDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    countryId: "",
    cityId: "",
    jobId: "",
    isStudent: false,
    organizationId: "",
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { isLoading: fetchingUserData, data: profileData } = useQuery({
    queryKey: ["userProfile"],
    queryFn: () => ProfileApi.GetLoggedInUserProfile(),
    enabled: !!isEdit,
  });

  const { data: { data: countries } = { data: { data: [] } } } = useQuery({
    queryKey: ["countries"],
    queryFn: () => LocationApiService.GetCountries(),
    onSucess: () => {
      queryClient.invalidateQueries({ queryKey: ["cities"] });
    },
  });

  const { data: jobs } = useQuery({
    queryKey: ["jobs"],
    queryFn: () => JobsApiService.GetJobs(),
  });

  const {
    getFieldProps,
    values,
    errors,
    touched,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: profileDetails,
    validationSchema: ProfileSchema,
    onSubmit: (values) => {
      if (isEdit) {
        handleButtonClick({
          ...values,
          profileId: profileDetails.profileId,
        });
      } else {
        handleButtonClick(values);
      }
    },
  });

  const { data: { data: cities } = { data: { data: [] } } } = useQuery({
    queryKey: ["cities", values.countryId],
    queryFn: () =>
      LocationApiService.GetCitiesByCountry({ id: values.countryId }),
    enabled: !!profileData || !!values.countryId,
  });

  const { data: companies } = useQuery({
    queryKey: ["companies"],
    queryFn: () => OrganizationApi.GetCompanies(),
    enabled: !!profileData || !!values.jobId,
  });

  const { data: schools } = useQuery({
    queryKey: ["schools"],
    queryFn: () => OrganizationApi.GetSchools(),
    enabled: !!profileData || !!values.isStudent,
  });

  useEffect(() => {
    if (profileData) {
      if (profileData.data) {
        const { profileDetails: profileInfo } = profileData.data;
        const updatedValues = {
          firstName: profileInfo.firstName,
          lastName: profileInfo.lastName,
          phoneNumber: profileInfo.phoneNumber,
          countryId: profileInfo.countryId,
          cityId: profileInfo.cityId,
          jobId: profileInfo.jobId,
          isStudent: profileInfo.student,
          organizationId: profileInfo.organizationId,
        };
        setValues(updatedValues);
        setProfileDetails(profileData.data.profileDetails);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  const handleCountryUpdate = (e) => {
    setFieldValue("countryId", e.target.value);
    if (values.cityId) {
      setFieldValue("cityId", "");
    }
  };

  useEffect(() => {
    if (
      values.firstName?.length > 0 &&
      values.lastName?.length > 0 &&
      values.phoneNumber?.length > 0 &&
      values.cityId &&
      values.countryId &&
      (values.jobId || !!values?.isStudent) &&
      values?.organizationId
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    console.log(values);
  }, [values]);

  return (
    <Box w="full" py={1} className="auth">
      {isLoading || fetchingUserData ? (
        <Loader />
      ) : (
        <>
          <Box w="full">
            <Stack as="form" spacing={5} onSubmit={handleSubmit}>
              <FormControl isInvalid={!!errors.firstName && touched.firstName}>
                <FormLabel htmlFor="firstName">First Name</FormLabel>
                <Input
                  variant="outline"
                  type="text"
                  {...getFieldProps("firstName")}
                />
                <FormErrorMessage>{errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.lastName && touched.lastName}>
                <FormLabel htmlFor="lastName">Last Name</FormLabel>
                <Input
                  variant="outline"
                  type="text"
                  {...getFieldProps("lastName")}
                />
                <FormErrorMessage>{errors.lastName}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!errors.phoneNumber && touched.phoneNumber}
              >
                <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                <Input
                  variant="outline"
                  type="text"
                  {...getFieldProps("phoneNumber")}
                />
                <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.countryId && touched.countryId}>
                <FormLabel htmlFor="country">Country</FormLabel>
                <Select
                  variant="outline"
                  placeholder=""
                  onChange={(e) => handleCountryUpdate(e)}
                  value={values.countryId}
                >
                  <option></option>
                  {countries &&
                    !!countries.values &&
                    countries.values.map(({ id, value }) => (
                      <option key={id} value={id}>
                        {value}
                      </option>
                    ))}
                </Select>
                <FormErrorMessage>{errors.countryId}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.cityId && touched.cityId}>
                <FormLabel htmlFor="cityId">City</FormLabel>
                <Select
                  variant="outline"
                  placeholder=""
                  onChange={(e) => setFieldValue("cityId", e.target.value)}
                  value={values.cityId}
                >
                  <option></option>
                  {cities &&
                    !!cities.values &&
                    cities?.values.map(({ id, value }) => (
                      <option key={id} value={id}>
                        {value}
                      </option>
                    ))}
                </Select>
                <FormErrorMessage>{errors.cityId}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.jobId && touched.jobId}>
                <FormLabel htmlFor="jobId">Job Title </FormLabel>
                <AutoComplete
                  options={jobs && jobs.data && jobs.data.values}
                  handleClick={(value) => setFieldValue("jobId", value)}
                  value={values.jobId}
                  {...getFieldProps("jobId")}
                />
                <FormErrorMessage>{errors.jobId}</FormErrorMessage>
              </FormControl>
              {(values.jobId || values.isStudent) && (
                <FormControl
                  isInvalid={!!errors.organizationId && touched.organizationId}
                >
                  <FormLabel htmlFor="organizationId">Organization</FormLabel>

                  <AutoComplete
                    name="oragnizationId"
                    options={
                      values.jobId
                        ? companies && companies.data.values
                        : schools && schools.data.values
                    }
                    handleClick={(value) => {
                      setFieldValue("organizationId", value);
                    }}
                    value={values.organizationId}
                    {...getFieldProps("organizationId")}
                  />
                  <FormErrorMessage>{errors.organizationId}</FormErrorMessage>
                </FormControl>
              )}
              <Checkbox
                name="isStudent"
                value={values.isStudent}
                onChange={(e) => setFieldValue("isStudent", e.target.checked)}
              >
                I am a Student
              </Checkbox>
              <Button
                type="submit"
                variant="brandPrimary"
                isDisabled={buttonDisabled}
              >
                {isEdit ? "Update profile" : "Continue"}
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProfileForm;
