import AuthLayout from "../components/Layouts/AuthLayout";
import BlankLayout from "../components/Layouts/BlankLayout";
import MainLayout from "../components/Layouts/MainLayout";

// Pages
import Login from "../pages/Auth/login/Login";
import Signup from "../pages/Auth/signup/Signup";
import Following from "../pages/Following";
import CompleteProfile from "../pages/Profile/CompleteProfile";
import { renderRoutes } from "./generate-routes";
import RecoverPassword from "../pages/Auth/recover-password/RecoverPassword";
import Verification from "../pages/Auth/verification/Verification";
import MiniMainLayout from "../components/Layouts/MiniMainLayout";
import User from "../pages/User";
import BasedOnGoal from "../pages/BasedOnGoal";
import Companies from "../pages/Companies";
import SuggestedCompanies from "../pages/Companies/SuggestedCompanies";
import CompanyDetails from "../pages/Companies/CompanyDetails";
import ChangePassword from "../pages/Auth/change-password/ChangePassword";
import Profile from "../pages/Profile";
import Home from "../pages/Home";
import Notifications from "../pages/Notifications";
import SettingsLayout from "../components/Layouts/SettingsLayout";
import SettingsChangePassword from "../pages/Settings/SettingsChangePassword";
import { Navigate } from "react-router-dom";
import ChangeEmail from "../pages/Settings/ChangeEmail";
import VisibilitySetting from "../pages/Settings/VisibilitySetting";
import AccountSetting from "../pages/Settings/AccountSetting";
import NotificationSetting from "../pages/Settings/NotificationSetting";
import Community from "../pages/Community";
import TermsOfService from "../pages/TermsOfService";
import PrivacyPolicy from "../pages/PrivacyPolicy";

export const paths = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  FOLLOWING: "/following",
  COMPLETE_PROFILE: "/complete-profile",
  RECOVER_PASSWORD: "/recover-password",
  VERIFICATION: "/verify-account/:id",
  USER: "/u/:id",
  BASED_ON_GOAL: "/goals",
  COMPANIES: "/companies_following",
  COMPANY_DETAILS: "/companies/:companyId",
  SUGGESTED_COMPANIES: "/companies/suggested",
  CHANGE_PASSWORD: "/change-password",
  PROFILE: "/profile",
  HOME: "/",
  NOTIFICATIONS: "/notifications",
  SETTINGS: "/settings",
  SETTINGS_CHANGE_PASSWORD: "/settings/change-password",
  CHANGE_EMAIL: "/settings/change-email",
  NOTIFICATION_SETTING: "/settings/notifications",
  VISIBILITY_SETTING: "/settings/visibility",
  ACCOUNT_SETTING: "/settings/account",
  COMMUNITY: "/community",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_SERVICE: "/terms-of-services",
};

export const routes = [
  {
    layout: AuthLayout,
    routes: [
      {
        name: "login",
        title: "Login page",
        component: Login,
        path: paths.LOGIN,
        isPublic: true,
      },
      {
        name: "signup",
        title: "Signup page",
        component: Signup,
        path: paths.SIGNUP,
        isPublic: true,
      },
      {
        name: "recover-password",
        title: "Recover Password page",
        component: RecoverPassword,
        path: paths.RECOVER_PASSWORD,
        isPublic: true,
      },
      {
        name: "verify-account",
        title: "Verify Account page",
        component: Verification,
        path: paths.VERIFICATION,
        isPublic: true,
      },
      {
        name: "change-password",
        title: "Change Password page",
        component: ChangePassword,
        path: paths.CHANGE_PASSWORD,
        isPublic: true,
      },
    ],
  },
  {
    layout: BlankLayout,
    routes: [
      {
        name: "profile",
        title: "Complete Profile",
        component: CompleteProfile,
        path: paths.COMPLETE_PROFILE,
      },
      {
        name: "home",
        title: "Home page",
        component: Home,
        path: "/",
        isPublic: true,
      },
    ],
  },
  {
    layout: MainLayout,
    routes: [
      {
        name: "following",
        title: "Following page",
        component: Following,
        path: paths.FOLLOWING,
      },
      {
        name: "goals",
        title: "Based on Goals",
        component: BasedOnGoal,
        path: paths.BASED_ON_GOAL,
      },
      {
        name: "profile",
        title: "Profile",
        component: Profile,
        path: paths.PROFILE,
      },
      {
        name: "notifications",
        title: "Notifications",
        component: Notifications,
        path: paths.NOTIFICATIONS,
      },
      {
        name: "community",
        title: "Community",
        component: Community,
        path: paths.COMMUNITY,
      },
    ],
  },
  {
    layout: MiniMainLayout,
    routes: [
      {
        name: "User",
        title: "User page",
        component: User,
        path: paths.USER,
      },
      {
        name: "Companies",
        title: "Companies Page",
        component: Companies,
        path: paths.COMPANIES,
      },
      {
        name: "Suggested Companies",
        title: "Suggested Companies Page",
        component: SuggestedCompanies,
        path: "/suggested-companies",
      },
      {
        name: "Company Details",
        title: "Company Details Page",
        component: CompanyDetails,
        path: "/company/:name",
      },
    ],
  },
  {
    layout: SettingsLayout,
    routes: [
      {
        name: "Settings",
        title: "Settings",
        component: () => <Navigate to={paths.CHANGE_EMAIL} />,
        path: paths.SETTINGS,
      },
      {
        name: "Change Password",
        title: "Change Password",
        component: SettingsChangePassword,
        path: paths.SETTINGS_CHANGE_PASSWORD,
      },
      {
        name: "Change Email",
        title: "Change Email",
        component: ChangeEmail,
        path: paths.CHANGE_EMAIL,
      },
      {
        name: "Visibility Setting",
        title: "Visibility Setting",
        component: VisibilitySetting,
        path: paths.VISIBILITY_SETTING,
      },
      {
        name: "Account Setting",
        title: "Account Setting",
        component: AccountSetting,
        path: paths.ACCOUNT_SETTING,
      },
      {
        name: "Notification Setting",
        title: "Notification Setting",
        component: NotificationSetting,
        path: paths.NOTIFICATION_SETTING,
      },
    ],
  },
  {
    layout: BlankLayout,
    routes: [
      {
        name: "Privacy Policy",
        title: "privacy-policy",
        component: PrivacyPolicy,
        path: paths.PRIVACY_POLICY,
        isPublic: true,
      },
      {
        name: "Terms of Service",
        title: "terms-of-service",
        component: TermsOfService,
        path: paths.TERMS_OF_SERVICE,
        isPublic: true,
      },
    ],
  },
];

export const Routes = renderRoutes(routes);
