import React from "react";
import { Box, Text } from "@chakra-ui/react";

const CompanyMaterials = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        By posting, uploading, inputting, providing or submitting your Content
        you are granting the Company to use your Content in connection with the
        operation of Company’s business including, but not limited to, the
        rights to transmit, publicly display, distribute, publicly perform,
        copy, reproduce, and translate your Content; and to publish your name in
        connection with your Content. <br /> <br />
        No compensation shall be paid with regard to the use of your Content.
        The Company shall have no obligation to publish or enjoy any Content you
        may send us and may remove your Content at any time.
        <br /> <br /> By posting, uploading, inputting, providing or submitting
        your Content you warrant and represent that you own all of the rights to
        your Content.
      </Text>
    </Box>
  );
};

export default CompanyMaterials;
