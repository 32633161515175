import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../../context/Auth";
import { paths } from "..";
import { isLive } from "../../utils";

const ProtectedRoute = ({ isPublic }) => {
  const isOnProduction = isLive();
  const { isAuthorized } = useAuthContext();
  const acceptedRoutesForProduction = [
    "/",
    "/terms-of-services",
    "/privacy-policy",
  ];

  return isOnProduction &&
    !acceptedRoutesForProduction.includes(window.location.pathname) ? (
    <Navigate to="/" />
  ) : isPublic && isAuthorized ? (
    <Navigate to={paths.COMMUNITY} />
  ) : isPublic || isAuthorized ? (
    <Outlet />
  ) : (
    !isAuthorized &&
    !isPublic &&
    !isOnProduction && <Navigate to={paths.LOGIN} />
  );
};

export default ProtectedRoute;
