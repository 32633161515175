import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "../../../service/auth/AuthApi.service";
import { useFormik } from "formik";
import * as Yup from "yup";

const RecoverPassword = () => {
  const [showMsg, setshowMsg] = useState(false);
  const toast = useToast();
  const { mutate: resetPassword, isPending } = useMutation({
    mutationFn: async (user) => await AuthApi.ResetPassword(user),
    onSuccess: (data) => {
      setshowMsg(true);
      if (showMsg) {
        toast({
          title: "Email Sent!",
          status: "success",
        });
      }
    },
  });

  const {
    getFieldProps,
    errors,
    touched,
    values: user,
    handleSubmit,
    isValid,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: {
      emailAddress: "",
    },
    onSubmit: (values) => {
      resetPassword(values);
    },
    validationSchema: Yup.object().shape({
      emailAddress: Yup.string()
        .email("Invalid Email Address")
        .required("Please enter email address"),
    }),
  });
  const Message = () => {
    return (
      <>
        <Text fontSize="md" maxW="md">
          A password reset instruction has been sent to your registered email.
          Please check your email to complete the password change request
        </Text>
        <Text my={2} fontSize="sm" display="flex" alignItems="center" gap={1}>
          Didn't recieve an email?
          <Text
            as="span"
            color="primary.500"
            _hover={{
              cursor: "pointer",
            }}
            onClick={() => resetPassword({ emailAddress: user.emailAddress })}
            data-testid="resendEmail"
          >
            {isPending ? <Spinner size="xs" /> : "Resend email"}
          </Text>
        </Text>
      </>
    );
  };
  return (
    <Box w="full">
      <h1 className="font-semibold text-3.5xl mb-8">Recover Password!</h1>
      {showMsg ? (
        <Message />
      ) : (
        <form onSubmit={handleSubmit}>
          <FormControl
            isInvalid={!!errors.emailAddress && touched.emailAddress}
          >
            <FormLabel htmlFor="emailAddress">Email Address</FormLabel>
            <Input
              variant="outline"
              type="email"
              id="emailAddress"
              {...getFieldProps("emailAddress")}
            />
            <FormErrorMessage>{errors.emailAddress}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            variant="brandPrimary"
            my={8}
            isLoading={isSubmitting}
            isDisabled={!(dirty && isValid)}
          >
            Continue
          </Button>
        </form>
      )}
    </Box>
  );
};

export default RecoverPassword;
