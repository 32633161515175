import React from "react";
import { Outlet } from "react-router-dom";
import Nav from "../Nav";
import { Container, Flex } from "@chakra-ui/react";
import Suggestions from "../Suggestions";

const MiniMainLayout = () => {
  return (
    <Container maxW="8xl" px={{ base: 4, lg: 12 }} py={{ base: 2, lg: 6 }}>
      <Nav />
      <Flex gap={8} my={{ base: 8, lg: 16 }}>
        <Flex justifyContent="center" w="full">
          <Outlet />
        </Flex>
        <Suggestions />
      </Flex>
    </Container>
  );
};

export default MiniMainLayout;
