import React from "react";
import { Box, Text } from "@chakra-ui/react";

const Miscellaneous = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        The governing law of the Terms shall be the substantive laws of the
        country where the Company is set up, except the conflict of laws rules.
        You shall not use the website in jurisdictions that do not give effect
        to all provisions of the Terms. <br /> <br />
        No joint venture, partnership, employment, or agency relationship shall
        be implied between you and the Company as a result of the Terms or use
        of the website.
        <br /> <br /> Nothing in the Terms shall be a derogation of the
        Company’s right to comply with governmental, court, police, and law
        enforcement requests or requirements regarding your enjoyment of the
        website.
        <br /> <br />
        If any part of the Terms is determined to be void or unenforceable in
        accordance with applicable law then the void or unenforceable clauses
        will be deemed superseded by valid and enforceable clauses shall be
        similar to the original version of the Terms and other parts and
        sections of the Terms shall be applicable to you and the Company.
        <br /> <br />
        The Terms constitute the entire agreement between you and the Company
        regarding the enjoyment of the website and the Terms supersede all prior
        or communications and offers, whether electronic, oral or written,
        between you and the Company.
        <br /> <br />
        The Company and its affiliates shall not be liable for a failure or
        delay to fulfill its obligations where the failure or delay results from
        any cause beyond Company’s reasonable control, including technical
        failures, natural disasters, blockages, embargoes, riots, acts,
        regulation, legislation, or orders of government, terroristic acts, war,
        or any other force outside of Company’s control.
        <br /> <br />
        In case of controversies, demands, claims, disputes, or causes of action
        between the Company and you relating to the website or other related
        issues, or the Terms, you and the Company agree to attempt to resolve
        such controversies, demands, claims, disputes, or causes of action by
        good faith negotiation, and in case of failure of such negotiation,
        exclusively through the courts of the country where the Company is set
        up.
      </Text>
    </Box>
  );
};

export default Miscellaneous;
