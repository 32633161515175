import { Box, Image, HStack, Text, Button, Flex } from "@chakra-ui/react";
import React from "react";
import { CloseIcon, PlusIcon } from "../Icons";
import { Link } from "react-router-dom";

const CompanyCard = ({ name, followers, following, image, id, ...props }) => {
  return (
    <Box py={2} {...props}>
      <HStack justify="space-between" maxW="95%">
        <Flex gap={4} as={Link} to={`/company/${name}`} display="flex">
          <Image boxSize="50px" objectFit="contain" src={image} />
          <Box>
            <Text fontWeight={600} color="primary.400">
              {name}
            </Text>
            <Text fontWeight={500} fontSize="12px" color="grey.600">
              {followers} Followers
            </Text>
          </Box>
        </Flex>

        {following ? (
          <Button
            leftIcon={<CloseIcon boxSize={5} color="primary.600" />}
            variant="link"
            fontSize="sm"
            color="primary.500"
          >
            {" "}
            Unfollow
          </Button>
        ) : (
          <Button
            leftIcon={<PlusIcon />}
            color="white"
            bgColor="primary.400"
            letterSpacing="-0.06px"
            borderRadius="lg"
          >
            Follow
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default CompanyCard;
