import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Avatar,
  Flex,
  Heading,
  Hide,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  UnorderedList,
} from "@chakra-ui/react";
import { NavLink as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { BellIcon, MenuIcon } from "../Icons";
import MobileNav from "./MobileNav";
import { logout, GetUserInfo } from "../../utils";
import { paths } from "../../routes";

const Nav = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({});
  const showMenu = () => {
    setShow(!show);
  };
  useEffect(() => {
    const userInfo = GetUserInfo();
    setUser(userInfo);
  }, []);
  const Link = ({ to, children }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
    return (
      <ChakraLink
        to={to}
        as={ReactRouterLink}
        variant="brandLink"
        _activeLink={
          isActive && {
            borderBottom: 4,
            borderColor: "primary.500",
            borderStyle: "solid",
            paddingBottom: 1.5,
          }
        }
      >
        {children}
      </ChakraLink>
    );
  };
  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowMobileMenu(true);
    } else {
      setShowMobileMenu(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Flex
      as="nav"
      alignItems="center"
      justify="space-between"
      py={{ base: 2.5 }}
    >
      <Hide above="lg">
        <MenuIcon boxSize={6} onClick={showMenu} />
      </Hide>
      <Flex
        alignItems="center"
        justify="space-between"
        flexBasis={{ xl: "72%" }}
        gap={{ lg: 8, xl: 0 }}
      >
        <Heading fontSize={{ base: "xl", lg: "3.5xl" }} color="primary.500">
          <ChakraLink
            as={ReactRouterLink}
            to={paths.FOLLOWING}
            _hover={{ textDecoration: "none" }}
          >
            hinterviews
          </ChakraLink>
        </Heading>

        {!showMobileMenu && (
          <UnorderedList display="flex" gap={{ lg: 8 }} styleType="none">
            <ListItem>
              <Link to={paths.FOLLOWING}>Following</Link>
            </ListItem>
            <ListItem>
              <Link to={paths.BASED_ON_GOAL}>Based on your goal</Link>
            </ListItem>
            <ListItem>
              <Link to={paths.COMPANIES}>Companies Following</Link>
            </ListItem>
            <ListItem>
              <Link to={paths.COMMUNITY}>Community</Link>
            </ListItem>
          </UnorderedList>
        )}
        {showMobileMenu && <MobileNav showMenu={showMenu} show={show} />}
      </Flex>
      <Flex alignItems="center" gap={4}>
        <ReactRouterLink to={paths.NOTIFICATIONS}>
          <BellIcon boxSize={6} />
        </ReactRouterLink>
        <Menu>
          <MenuButton>
            <Avatar
              name={`${user.fname} ${user.lname}`}
              // src={user.image}
              size={{ base: "xs", lg: "md" }}
            />
          </MenuButton>
          <MenuList>
            <MenuItem as={ReactRouterLink} to={paths.PROFILE}>
              Profile
            </MenuItem>
            <MenuItem as={ReactRouterLink} to={paths.SETTINGS}>
              Settings
            </MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Nav;
