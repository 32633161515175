import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  "stepper",
  "indicator",
  "separator",
  "step",
]);

export const Stepper = helpers.defineMultiStyleConfig({
  baseStyle: {
    indicator: {
      boxShadow: "lg",
      flexDirection: "column",
      width: "8px",
      height: "8px",
    },
    step: {
      alignItems: "baseline",
    },
    separator: {
      left: "3px !important",
      top: "17px !important",
      maxHeight: "calc(100% - var(--stepper-indicator-size) + 14px) !important",
      border: "1px dashed #EB5017",
      background: "white !important",
      width: "0px !important",
    },

    // separator: {
    //   fontWeight: "medium",
    //   lineHeight: "normal",
    //   color: "gray.600",
    // },
  },
  // sizes: {
  //   sm: {
  //     separator: {
  //       fontSize: "0.75rem",
  //       px: 2,
  //       py: 1,
  //     },
  //   },
  //   md: {
  //     item: {
  //       fontSize: "0.875rem",
  //       px: 3,
  //       py: 2,
  //     },
  //   },
  // },
  // variants: {
  //   bold: {
  //     separator: {
  //       fontWeight: "bold",
  //     },
  //     indicator: {
  //       boxShadow: "xl",
  //     },
  //   },
  //   colorful: {
  //     separator: {
  //       color: "orange.600",
  //     },
  //     indicator: {
  //       bg: "orange.100",
  //     },
  //   },
  // },
  // defaultProps: {
  //   size: "md",
  // },
});
