import HttpService from "..";
import { injectAuthorizationInterceptor } from "../../utils";

const followApiService = new HttpService({
  baseURL: "https://hinterview-backend-service.azurewebsites.net/api/follow",
});
injectAuthorizationInterceptor(followApiService);

export const FollowApi = {
  async FollowUser({ profileId, ...dto }) {
    return followApiService.SendRequest({
      method: "post",
      path: `/${profileId}`,
      body: dto,
    });
  },

  async GetFollowers() {
    return followApiService.SendRequest({
      method: "get",
      path: "/get-followers",
    });
  },

  async GetFollowing() {
    return followApiService.SendRequest({
      method: "get",
      path: "/get-followees",
    });
  },

  async GetMutualConnection(dto) {
    return followApiService.SendRequest({
      method: "get",
      path: `/get-mutual-connection/${dto.connectionId}`,
    });
  },

  async getFollowingCount() {
    return followApiService.SendRequest({
      method: "get",
      path: "/get-followee-count",
    });
  },

  async getFollowerCount() {
    return followApiService.SendRequest({
      method: "get",
      path: "/getFollowersCount",
    });
  },
};
