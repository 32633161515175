import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Link,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { paths } from "../../routes";

const SettingSideBar = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const isActive = (to) => location.pathname === to;

  const settingRoutes = [
    {
      name: "Change Email Address",
      path: paths.CHANGE_EMAIL,
    },
    {
      name: "Change Password",
      path: paths.SETTINGS_CHANGE_PASSWORD,
    },
    {
      name: "Visibility Setting",
      path: paths.VISIBILITY_SETTING,
    },
    {
      name: "Notification Setting",
      path: paths.NOTIFICATION_SETTING,
    },
    {
      name: "Account Setting",
      path: paths.ACCOUNT_SETTING,
    },
  ];

  return (
    <Card
      px={{ md: 5 }}
      hideBelow={isOpen ? "" : "md"}
      w={{ base: "100%", md: "auto" }}
    >
      <CardHeader>
        <Heading as="h6" size={{ base: "sm", md: "md" }}>
          Settings
        </Heading>
      </CardHeader>
      <CardBody mb={4}>
        <Stack spacing={{ base: 6, md: 10 }} fontSize="sm">
          {settingRoutes.map(({ name, path }) => (
            <Link
              key={path}
              as={NavLink}
              to={path}
              _activeLink={
                isActive(path) && {
                  borderLeft: 4,
                  borderColor: "primary.500",
                  borderStyle: "solid",
                  paddingLeft: 1.5,
                  marginLeft: -1.5,
                }
              }
              onClick={() => {
                isOpen && setIsOpen(false);
              }}
            >
              {name}
            </Link>
          ))}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default SettingSideBar;
