import React from "react";
import {
  Box,
  Flex,
  FormControl,
  Icon,
  Text,
  chakra,
  useCheckbox,
} from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa6";

const FilterField = (props) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props);
  return (
    <FormControl>
      <chakra.label
        display="flex"
        flexDirection="row"
        alignItems="center"
        gridColumnGap={2}
        py={1}
        cursor="pointer"
        {...htmlProps}
        mb={2}
      >
        <input {...getInputProps()} hidden />
        <Flex
          alignItems="center"
          justifyContent="center"
          border="2px solid"
          borderColor="black"
          borderRadius="md"
          w="18px"
          h="18px"
          marginRight={4}
          {...getCheckboxProps()}
        >
          {state.isChecked && <Icon as={FaCheck} boxSize={3} />}
        </Flex>
        <Text {...getLabelProps()}>{props.label}</Text>
      </chakra.label>
      <Box minH={2}>{state.isChecked && props.children}</Box>
    </FormControl>
  );
};

export default FilterField;
