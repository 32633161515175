import React from "react";
import { Box, Text } from "@chakra-ui/react";

const ThirdPartyService = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        The website may include links to other websites, applications, and
        platforms (hereinafter the{" "}
        <Text as="span" fontWeight={500}>
          “Linked Sites“
        </Text>
        ). The Company does not control the Linked Sites, and shall not be
        responsible for the content and other materials of the Linked Sites. The
        Company makes these links available to you for providing the
        functionality or services on the website.
      </Text>
    </Box>
  );
};

export default ThirdPartyService;
