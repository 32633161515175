import React from "react";
import { Box, Text } from "@chakra-ui/react";

const UserGeneratedContent = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        You may share personal data with us when you submit user generated
        content to our website, including via our rewards program, forums,
        message boards and blogs on our website. Please note that any
        information you post or disclose on our website will become public
        information, and will be available to other users of our website and to
        the general public. We urge you to be very careful when deciding to
        disclose your personal data, or any other information, on our website.
        Such personal data and other information will not be private or
        confidential once it is published on our website.
        <br /> <br /> If you provide feedback to us, we may use and disclose
        such feedback on our website, provided we do not associate such feedback
        with your personal data. If you have provided your consent to do so, we
        may post your first and last name along with your feedback on our
        website. We will collect any information contained in such feedback and
        will treat the personal data in it in accordance with this Policy.
      </Text>
    </Box>
  );
};

export default UserGeneratedContent;
