import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Icon,
  Link,
  SkeletonCircle,
  SkeletonText,
  Spinner,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FollowIcon,
  PencilIcon,
  ShareIcon,
  StarIcon,
  UnfollowOutlineIcon,
  UserGroupFilledIcon,
  UserGroupOutlineIcon,
} from "../Icons";
import { useLocation } from "react-router-dom";
import { paths } from "../../routes";
import EditProfileModal from "../Modals/EditProfileModal";
import { ProfileApi } from "../../service/profile/ProfileApi.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GetUserInfo } from "../../utils";
import { FollowApi } from "../../service/follow/FollowApi.service";

const UserProfile = ({ userId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const user = GetUserInfo();
  const queryClient = useQueryClient();
  const { data: responseData = {}, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: () => ProfileApi.GetProfileById({ userId: userId ?? user.uid }),
  });
  const {
    data: { profileDetails: profile = null } = { data: {} || null },
    error,
  } = responseData || {};

  const { data: mutualConnectionData = {} } = useQuery({
    queryKey: ["mutualConnection"],
    queryFn: () =>
      FollowApi.GetMutualConnection({
        connectionId: !!profile && profile.profileId,
      }),
    enabled: !!profile && !!userId,
  });

  const {
    data: { followers: mutuals, mutualFollowersCount } = {},
    mutualConnectionError,
  } = mutualConnectionData || {};

  const { mutate: follow, isPending } = useMutation({
    mutationFn: async () =>
      await FollowApi.FollowUser({
        profileId: profile && profile.profileId,
        action: profile.followedBack ? 0 : 1,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["profile"],
        refetchType: "all",
      });
    },
  });

  return (
    <>
      <Box as="aside" minW={{ base: "220px", md: "260px" }} mx="auto">
        <Card px={4} py={6} borderRadius="lg" boxShadow="md">
          {!isLoading && (
            <>
              {!!profile && (
                <>
                  <CardHeader
                    py={0}
                    mx="auto"
                    pos="relative"
                    maxW={{ base: "200px" }}
                  >
                    <Avatar
                      size="xl"
                      name={`${profile.firstName} ${profile.lastName}`}
                      src={profile?.avatar}
                    />
                    {location.pathname === paths.PROFILE && (
                      <Box pos="absolute" top={0} right={-4}>
                        <PencilIcon
                          h={6}
                          w={6}
                          onClick={onOpen}
                          _hover={{ cursor: "pointer" }}
                        />
                      </Box>
                    )}
                  </CardHeader>
                  <CardBody
                    pt={3}
                    pb={2}
                    px={0}
                    maxW={{ base: "200px" }}
                    mx="auto"
                  >
                    <Stack divider={<StackDivider />} spacing={5}>
                      <Stack textAlign="center" spacing={2}>
                        {!userId ? (
                          <Link
                            fontSize="lg"
                            color="primary.500"
                            fontWeight={600}
                            href={paths.PROFILE}
                          >
                            {profile.firstName} {profile.lastName}
                          </Link>
                        ) : (
                          <Text
                            as="h6"
                            fontSize="lg"
                            color="primary.500"
                            fontWeight={600}
                          >
                            {profile.firstName} {profile.lastName}
                          </Text>
                        )}
                        <Text fontSize="sm" fontWeight={600} color="grey.600">
                          {profile.title}
                        </Text>
                        <Text fontSize="sm" fontWeight={500} color="grey.500">
                          {profile?.organization}
                        </Text>
                        <Text fontSize="sm" fontWeight={500} color="grey.500">
                          {profile.city} {profile.country}
                        </Text>
                        {userId && (
                          <Button
                            maxW="200px"
                            mx="auto"
                            variant="brandPrimary"
                            py={2}
                            borderRadius="md"
                            onClick={follow}
                            display="flex"
                            gap={2}
                            alignItems="center"
                          >
                            {isPending ? (
                              <Spinner />
                            ) : (
                              <>
                                <Icon
                                  as={
                                    profile.followedBack
                                      ? UnfollowOutlineIcon
                                      : FollowIcon
                                  }
                                />
                                <Text as="span">
                                  {profile.followedBack ? "Unfollow" : "Follow"}
                                </Text>
                              </>
                            )}
                          </Button>
                        )}
                      </Stack>
                      <Stack
                        spacing={4}
                        w="full"
                        fontSize="sm"
                        fontWeight={500}
                        color="grey.500"
                      >
                        <Flex justify="space-between" color="green.400">
                          <Text display="flex" alignItems="center" gap={1}>
                            <Icon as={StarIcon} />
                            Upvotes
                          </Text>
                          <Text>{profile.upvoteCount}</Text>
                        </Flex>
                        <Flex justify="space-between">
                          <Text display="flex" alignItems="center" gap={1}>
                            <Icon as={UserGroupFilledIcon} />
                            Followers
                          </Text>
                          <Text>{profile.followerCount}</Text>
                        </Flex>
                        <Flex justify="space-between">
                          <Text display="flex" alignItems="center" gap={1}>
                            <Icon as={UserGroupOutlineIcon} color="white" />
                            Following
                          </Text>
                          <Text>{profile.followeeCount}</Text>
                        </Flex>
                        {userId && (
                          <Stack w="full">
                            {!mutualConnectionError && (
                              <>
                                <Text>
                                  Mutual connection: {mutualFollowersCount}
                                </Text>
                                {!!mutuals && mutuals.length > 0 && (
                                  <Flex gap={5} alignItems="center" mt={2}>
                                    <Flex pos="relative">
                                      <Avatar
                                        size="xs"
                                        name={mutuals[0]?.firstName}
                                      ></Avatar>
                                      {!!mutuals[1] && (
                                        <Avatar
                                          pos="absolute"
                                          size="xs"
                                          left={4}
                                          name={mutuals[1].firstName}
                                        ></Avatar>
                                      )}
                                    </Flex>
                                    <Flex alignItems="center">
                                      <Text fontSize="xs">
                                        {mutuals[0]?.firstName}{" "}
                                        {mutuals[0]?.lastName}
                                      </Text>

                                      {!!mutuals[1] && (
                                        <Text fontSize="xs">
                                          {","}
                                          {""}
                                          {mutuals[1]?.firstName}{" "}
                                          {mutuals[1]?.lastName}
                                        </Text>
                                      )}
                                    </Flex>
                                  </Flex>
                                )}
                                <StackDivider bgColor="#E4E7EC" />

                                <Badge
                                  color="#CC400C"
                                  bgColor="secondary.50"
                                  fontSize="xs"
                                  my={4}
                                  py={1}
                                >
                                  Achieved Goal: {profile.achievedGoal}
                                </Badge>
                              </>
                            )}
                          </Stack>
                        )}
                      </Stack>
                    </Stack>

                    {!userId && (
                      <Box w="full" mt={6}>
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          gap={2}
                          color="prinary.500"
                        >
                          <ShareIcon />
                          <Text
                            fontSize="sm"
                            color="primary.500"
                            fontWeight="medium"
                          >
                            Share profile link
                          </Text>
                        </Flex>
                      </Box>
                    )}
                  </CardBody>
                </>
              )}
              {!profile && error && (
                <CardBody>
                  <Text> There was a problem loading the information</Text>
                </CardBody>
              )}
            </>
          )}
          {isLoading && (
            <>
              <Center>
                <SkeletonCircle size={{ base: 16, md: 20 }} />
              </Center>
              <SkeletonText
                mt={2}
                noOfLines={6}
                spacing="4"
                skeletonHeight="2"
              />
            </>
          )}
        </Card>
      </Box>

      <EditProfileModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default UserProfile;
