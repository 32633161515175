import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { GoalsApi } from "../../../service/goals/GoalsApi.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { ProfileApi } from "../../../service/profile/ProfileApi.service";
import * as Yup from "yup";

const AddNewGoalsModal = ({ isOpen, onClose, activeGoals }) => {
  const queryClient = useQueryClient();
  const [selectedGoals, setSelectedGoals] = useState([]);
  const toast = useToast();
  console.log(activeGoals);
  const { data: responseData = {}, isLoading } = useQuery({
    queryKey: ["goals"],
    queryFn: () => GoalsApi.GetGoals(),
  });
  const { data: { values: goals } = {}, error } = responseData || {};
  const selectGoal = (goal) => {
    if (
      !values.goals.includes(goal.id) &&
      values.goals.length < 2 - activeGoals.length
    ) {
      setFieldValue("goals", [...values.goals, goal.id]);
      setSelectedGoals([...selectedGoals, goal]);
    } else {
      setFieldValue(
        "goals",
        values.goals.filter((g) => g !== goal.id)
      );
      const newGoals = selectedGoals.filter((g) => g.id !== goal.id);
      setSelectedGoals(newGoals);
    }
  };

  const { isPending: loading, mutate: addUserGoals } = useMutation({
    mutationFn: async ({ goals }) =>
      await ProfileApi.CreateUserGoal({ goals: goals }),
    onSuccess: ({ data, error }) => {
      if (data) {
        toast({
          title: "Goal added successfully",
          status: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["userGoals"],
          refetchType: "all",
        });
        setSelectedGoals([]);
        setFieldValue("goals", []);
        onClose();
      } else if (error) {
        toast({
          title: "Error adding goal",
          status: "error",
        });
      }
    },
  });

  const { errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      goals: [],
    },
    validationSchema: Yup.object().shape({
      goals: Yup.array().required("Required"),
    }),
    onSubmit: (values) => {
      addUserGoals(values);
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} py={{ base: 6, lg: 10 }}>
      <ModalOverlay />
      <ModalContent py={{ base: 6, lg: 10 }}>
        <ModalHeader>Add new goal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack as="form" onSubmit={handleSubmit}>
            <FormControl isInvalid={!!errors.goals && touched.goals}>
              <FormLabel>
                What is your goal? {""}
                <Text as="span" color="grey.600" fontSize="sm" fontWeight={400}>
                  (Select a maximum of two goals)
                </Text>
              </FormLabel>

              <InputGroup
                size="md"
                borderColor="grey.300"
                borderWidth="1px"
                px={4}
                py={3}
                borderRadius="md"
              >
                {!!selectedGoals && (
                  <Flex gap={3}>
                    {selectedGoals.map((goal, index) => (
                      <Badge
                        key={index}
                        variant="select"
                        borderColor="primary.500"
                        bgColor="primary.50"
                      >
                        {goal.value}
                      </Badge>
                    ))}
                  </Flex>
                )}
              </InputGroup>
              <FormErrorMessage>{errors.goals}</FormErrorMessage>
            </FormControl>
            <Box my={6} p={4} bgColor="grey.75" borderRadius="lg">
              <Text color="grey.900" fontSize="sm" fontWeight={500}>
                Suggested goals
              </Text>
              <Flex
                my={6}
                columnGap={{ base: 2, lg: 3 }}
                rowGap={4}
                wrap="wrap"
              >
                {isLoading && !error ? (
                  <Spinner />
                ) : (
                  <>
                    {goals &&
                      goals.map((goal) => {
                        const isGoalActive =
                          activeGoals &&
                          activeGoals?.some(
                            (activeGoal) => activeGoal.goalId === goal.id
                          );

                        return (
                          !isGoalActive && (
                            <Badge
                              key={goal.id}
                              variant="select"
                              borderColor={
                                values.goals.includes(goal.id) && "primary.500"
                              }
                              bgColor={
                                values.goals.includes(goal.id) && "primary.50"
                              }
                              onClick={() => selectGoal(goal)}
                            >
                              {goal.value}
                            </Badge>
                          )
                        );
                      })}
                  </>
                )}
                {error && (
                  <Center>
                    <Text> There was a problem fetching goals</Text>
                  </Center>
                )}
              </Flex>
            </Box>
            <Button
              type="submit"
              variant="brandPrimary"
              isDisabled={values.goals.length === 0}
              isLoading={loading}
            >
              Add goal
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddNewGoalsModal;
