import { Box } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";

const BlankLayout = () => {
  return (
    <Box as="main" minH="100vh">
      <Outlet />
    </Box>
  );
};

export default BlankLayout;
