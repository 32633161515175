import UserGeneratedContent from "../components/PolicyAndService/policydata/UserGeneratedContent";
import Advertising from "../components/PolicyAndService/policydata/Advertising";
import CollectPersonalData from "../components/PolicyAndService/policydata/CollectPersonalData";
import Cookies from "../components/PolicyAndService/policydata/Cookies";
import PurposeOfProcessing from "../components/PolicyAndService/policydata/PurposeOfProcessing";
import SharingData from "../components/PolicyAndService/policydata/SharingData";
import InternationalDataTransfer from "../components/PolicyAndService/policydata/InternationalDataTransfer";
import Security from "../components/PolicyAndService/policydata/Security";
import Retention from "../components/PolicyAndService/policydata/Retention";
import OurPolicyOnKids from "../components/PolicyAndService/policydata/OurPolicyOnKids";
import Complaints from "../components/PolicyAndService/policydata/Complaints";
import ContactInfo from "../components/PolicyAndService/policydata/ContactInfo";
import YourAccount from "../components/PolicyAndService/termsOfService/YourAccount";
import Services from "../components/PolicyAndService/termsOfService/Services";
import ThirdPartyService from "../components/PolicyAndService/termsOfService/ThirdPartyService";
import ProhibitedUses from "../components/PolicyAndService/termsOfService/ProhibitedUses";
import CompanyMaterials from "../components/PolicyAndService/termsOfService/CompanyMaterials";
import Disclaimer from "../components/PolicyAndService/termsOfService/Disclaimer";
import Indemnification from "../components/PolicyAndService/termsOfService/Indemnification";
import Termination from "../components/PolicyAndService/termsOfService/Termination";
import Miscellaneous from "../components/PolicyAndService/termsOfService/Miscellaneous";

export const policyMenuData = [
  {
    title: "Purposes of Processing",
    hash: "purposes-of-processing",
    data: <PurposeOfProcessing />,
  },
  {
    title: "Collecting Your Personal Data ",
    hash: "collecting-personal-data",
    data: <CollectPersonalData />,
  },
  {
    title: "Cookies",
    hash: "cookies",
    data: <Cookies />,
  },
  {
    title: "Advertising",
    hash: "advertising",
    data: <Advertising />,
  },
  {
    title: "Sharing Your Personal Data",
    hash: "sharing-personal-data",
    data: <SharingData />,
  },
  {
    title: "User Generated Content",
    hash: "user-generated-content",
    data: <UserGeneratedContent />,
  },
  {
    title: "International Data Transfer",
    hash: "international-data-transfer",
    data: <InternationalDataTransfer />,
  },
  {
    title: "Security",
    hash: "security",
    data: <Security />,
  },
  {
    title: "Retention",
    hash: "retention",
    data: <Retention />,
  },
  {
    title: "Our Policy on Children",
    hash: "children-policy",
    data: <OurPolicyOnKids />,
  },
  {
    title: "Complaints",
    hash: "complaints",
    data: <Complaints />,
  },
  {
    title: "Contact Information",
    hash: "contact-info",
    data: <ContactInfo />,
  },
];

export const termsMenuData = [
  {
    title: "Your Account",
    hash: "your-account",
    data: <YourAccount />,
  },
  {
    title: "Services ",
    hash: "services",
    data: <Services />,
  },
  {
    title: "Third Party Services",
    hash: "third-party-services",
    data: <ThirdPartyService />,
  },
  {
    title: "Prohibited Uses and Intellectual Property",
    hash: "prohibited-intellectual-property",
    data: <ProhibitedUses />,
  },
  {
    title: "The Company Material",
    hash: "company-material",
    data: <CompanyMaterials />,
  },
  {
    title: "Disclaimer of Certain Liabilities",
    hash: "disclaimer",
    data: <Disclaimer />,
  },
  {
    title: "Indemnification",
    hash: "indemnification",
    data: <Indemnification />,
  },
  {
    title: "Termination and Access Restriction",
    hash: "termination",
    data: <Termination />,
  },
  {
    title: "Miscellaneous",
    hash: "miscellaneous",
    data: <Miscellaneous />,
  },

  {
    title: "Complaints",
    hash: "complaints",
    data: <Complaints />,
  },
  {
    title: "Contact Information",
    hash: "contact-info",
    data: <ContactInfo />,
  },
];
