import {
  Step,
  StepDescription,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import { CloseIcon } from "../Icons";
import { useState } from "react";

function Steps({ steps }) {
  const { activeStep } = useSteps({
    index: steps.length,
    count: steps.length,
  });
  const [showDescription, setShowDescription] = useState(
    new Array(steps.length).fill(false)
  );

  const toggleDescription = (index) => {
    const updatedShowDescription = [...showDescription];
    updatedShowDescription[index] = !updatedShowDescription[index];
    setShowDescription(updatedShowDescription);
  };

  return (
    <Stepper
      index={activeStep}
      orientation="vertical"
      py={2}
      size="sm"
      gap={0}
      colorScheme="secondary"
    >
      {steps.map((step, index) => (
        <Step key={step.id} w="full">
          <StepIndicator>
            <StepStatus incomplete={<StepNumber />} active={<StepNumber />} />
          </StepIndicator>

          <Box flexShrink="0" w="full">
            <StepTitle w="fulls" display="flex" style={{ fontWeight: "400" }}>
              {step.title}
              {!!step.notes && !showDescription[index] && (
                <Button
                  ml="87px"
                  variant="link"
                  fontSize="12px"
                  colorScheme="primary"
                  onClick={() => toggleDescription(index)}
                >
                  See note
                </Button>
              )}
            </StepTitle>

            <StepDescription
              w={{ base: "90%", xl: "70%" }}
              minHeight={index === steps.length - 1 ? "" : "40px"}
            >
              {showDescription[index] && (
                <Flex
                  w="full"
                  justify="space-between"
                  fontSize="sm"
                  color="grey.800"
                  bgColor="secondary.50"
                  px={{ base: 1.5, lg: 4 }}
                  py={2}
                  my={2}
                >
                  <Text as="span">{step.notes}</Text>
                  <CloseIcon
                    _hover={{
                      cursor: "pointer",
                    }}
                    boxSize="17px"
                    onClick={() => toggleDescription(index)}
                  />
                </Flex>
              )}
            </StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
}
export default Steps;
