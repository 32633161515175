import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../components/Icons";
import { followingCompany } from "../../mockDB";
import FollowerCard from "../../components/Followers/FollowerCard";

const CompanyDetails = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Box w="full">
      <Box mt={{ base: 6, lg: 0 }} mb={6} w="full">
        <Button
          variant="link"
          leftIcon={<ArrowLeftIcon boxSize={4} />}
          _hover={{ textDecor: "none" }}
          onClick={goBack}
        >
          Back
        </Button>
      </Box>
      <Card py={6} px={{ base: 4, lg: 10 }} maxW={{ base: "none", lg: "85%" }}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex>
            <Image
              boxSize="50px"
              src="https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.jpg"
              objectFit="contain"
            />
            <Box>
              <Text fontSize={{ lg: "lg" }}>Microsoft</Text>
              <Text fontSize="sm">600 Followers</Text>
            </Box>
          </Flex>
          <Button variant="outline">Unfollow</Button>
        </Flex>
        <Text my={4} fontSize={{ base: "xs" }}>
          Software Development. Redmond, Washington
        </Text>
        <Divider my={5} color="#B6D8FF" />
        <Box p={5} bgColor="#F9FAFB">
          <Text fontWeight={500} fontSize="sm">
            Microsoft Recruitment Data
          </Text>
          <Flex justifyContent="center" alignItems="center">
            <Text my={10}>No Data Available Yet!</Text>
          </Flex>
        </Box>
      </Card>
      <Stack my={6} spacing={6}>
        <Heading as="h6" size="sm">
          Followings with Microsoft
        </Heading>
        <Flex flexDir={{ base: "column", md: "row" }} gap={6}>
          {followingCompany.map((data) => (
            <FollowerCard key={data.id} {...data} />
          ))}
        </Flex>
      </Stack>
    </Box>
  );
};

export default CompanyDetails;
