import React from "react";
import { Box, Text } from "@chakra-ui/react";

const Services = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        The website allows you to use Services available on the website. You
        shall not use the services for the illegal aims. <br /> <br />
        We may, at our sole discretion, set fees for using the website for you.
        All prices are published separately on relevant pages on the website. We
        may, at our sole discretion, at any time change any fees.
        <br /> <br /> We may use certified payment systems, which also may have
        their commissions. Such commissions may be implied on you when you
        choose a particular payment system. Detailed information about
        commissions of such payment systems may be found on their websites.
      </Text>
    </Box>
  );
};

export default Services;
