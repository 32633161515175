import React from "react";
import { Box, ListItem, Text, UnorderedList, Heading } from "@chakra-ui/react";

const Advertising = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        We may use other companies to serve third-party advertisements when you
        visit and use the website. These companies may collect and use click
        stream information, browser type, time and date, subject of
        advertisements clicked or scrolled over during your visits to the
        website and other websites in order to provide advertisements about
        goods and services likely to be of interest to you. These companies
        typically use tracking technologies to collect this information. Other
        companies’ use of their tracking technologies is subject to their own
        privacy policies.
      </Text>

      <Heading fontSize="16px" my="20px">
        Using Your Personal Data
      </Heading>

      <Text w="100%" lineHeight={1.5} my="20px">
        We may use your personal data as follows:
      </Text>

      <UnorderedList display="flex" flexDirection="column" gap={3}>
        <ListItem>
          to operate, maintain, and improve our website, products, and services;
        </ListItem>
        <ListItem>
          to manage your account, including to communicate with you regarding
          your account, if you have an account on our website;
        </ListItem>
        <ListItem>
          to operate and administer our rewards program and other promotions you
          participate in on our website;
        </ListItem>
        <ListItem>
          to send information including technical notices, updates, security
          alerts, and support and administrative messages;
        </ListItem>
        <ListItem>
          with your consent, to send you marketing e-mails about upcoming
          promotions, and other news, including information about products and
          services offered by us and our affiliates. You may opt-out of
          receiving such information at any time: such marketing emails tell you
          how to “opt-out.” Please note, even if you opt out of receiving
          marketing emails, we may still send you non-marketing emails.
          Non-marketing emails include emails about your account with us (if you
          have one) and our business dealings with you;
        </ListItem>
        <ListItem>to process payments you make via our website;</ListItem>
        <ListItem>
          as we believe necessary or appropriate (a) to comply with applicable
          laws; (b) to comply with lawful requests and legal process, including
          to respond to requests from public and government authorities; (c) to
          enforce our Policy; and (d) to protect our rights, privacy, safety or
          property, and/or that of you or others;
        </ListItem>
        <ListItem>for analysis and study services; and</ListItem>
        <ListItem>
          as described in the “Sharing of your Personal Data” section below.
        </ListItem>
      </UnorderedList>
    </Box>
  );
};

export default Advertising;
