import { Box, ListItem, Text, UnorderedList, Heading } from "@chakra-ui/react";
import React from "react";

const CollectPersonalData = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my={5}>
        We collect information about you in the following ways:
      </Text>

      <Text w="100%" lineHeight={1.5} my={3}>
        The information you give to us includes:
      </Text>

      <UnorderedList display="flex" flexDirection="column" gap={3}>
        <ListItem>
          the personal data you provide when you register to use our website,
          including your name, postal address, email address, telephone number,
          username, password and demographic information;
        </ListItem>
        <ListItem>
          the personal data that may be contained in any video, comment or other
          submission you upload or post to the website;
        </ListItem>
        <ListItem>
          the personal data you provide in connection with our rewards program
          and other promotions we run on the website;
        </ListItem>
        <ListItem>
          the personal data you provide when you report a problem with our
          website or when we provide you with customer support;
        </ListItem>
        <ListItem>
          the personal data you provide when you make a purchase thorough our
          website; and
        </ListItem>
        <ListItem>
          the personal data you provide when you correspond with us by phone,
          email or otherwise.
        </ListItem>
      </UnorderedList>

      <Heading fontSize="16px" my="20px">
        Information from Social Networking Websites.
      </Heading>

      <Text w="100%" lineHeight={1.5} my="20px">
        Our website includes interfaces that allow you to connect with social
        networking sites (each a “SNS”). If you connect to a SNS through our
        website, you authorize us to access, use and store the information that
        you agreed the SNS could provide to us based on your settings on that
        SNS. We will access, use and store that information in accordance with
        this Policy. You can revoke our access to the information you provide in
        this way at any time by amending the appropriate settings from within
        your account settings on the applicable SNS.
      </Text>

      <Heading fontSize="16px" my="20px">
        Information Automatically Collected.
      </Heading>

      <Text w="100%" lineHeight={1.5} my="20px">
        We automatically log information about you and your computer or mobile
        device when you access our website. For example, when visiting our
        website, we log your computer or mobile device operating system name and
        version, manufacturer and model, browser type, browser language, screen
        resolution, the website you visited before browsing to our website,
        pages you viewed, how long you spent on a page, access times and
        information about your use of and actions on our website. We collect
        this information about you using cookies.
      </Text>

      <Heading fontSize="16px" my="20px">
        Automated Decision Making and Profiling.
      </Heading>

      <Text w="100%" lineHeight={1.5} my="20px">
        We do not use your personal data for the purposes of automated
        decision-making. However, we may do so in order to fulfill obligations
        imposed by law, in which case we will inform you of any such processing
        and provide you with an opportunity to object.
      </Text>
    </Box>
  );
};

export default CollectPersonalData;
