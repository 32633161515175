import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Oauth from "../../../components/Auth/Oauth";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordInput from "../../../components/Auth/PasswordInput";
import { useAuthContext } from "../../../context/Auth";
import { paths } from "../../../routes";

const Login = () => {
  const { handleLogin } = useAuthContext();
  const toast = useToast();
  const {
    touched,
    errors,
    handleSubmit,
    getFieldProps,
    isValid,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: {
      emailAddress: "",
      password: "",
    },
    onSubmit: async (values) => {
      const { data, error } = await handleLogin(values);
      if (data) {
        toast({
          title: "Welcome back!",
          status: "success",
        });
      }
      if (error) {
        toast({
          title: error.defaultMessage,
          status: "error",
        });
      }
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Required").min(8),
      emailAddress: Yup.string().email("Invalid email").required("Required"),
    }),
  });
  return (
    <Box h="max-content" w="full">
      <Heading fontSize="3.5xl" mb={2} fontWeight={600} role="heading">
        Welcome back!
      </Heading>
      <Text fontSize="sm" color="office-brown.700">
        Don't have an account ?{" "}
        <Link to={paths.SIGNUP} className="text-primary font-semibold">
          Sign Up
        </Link>
      </Text>
      <Box as="form" my={6} onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <FormControl
            isInvalid={!!errors.emailAddress && touched.emailAddress}
          >
            <FormLabel htmlFor="emailAddress">Email Address</FormLabel>
            <Input
              variant="outline"
              type="email"
              id="emailAddress"
              {...getFieldProps("emailAddress")}
            />
            <FormErrorMessage aria-label="email error message">
              {errors.emailAddress}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password && touched.password}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <PasswordInput id="password" {...getFieldProps("password")} />
            <FormErrorMessage aria-label="password error message">
              {errors.password}
            </FormErrorMessage>
          </FormControl>
        </Stack>
        <Button
          type="submit"
          variant="brandPrimary"
          my={8}
          isDisabled={!(dirty && isValid)}
          isLoading={isSubmitting}
          data-testid="login-btn"
        >
          Login
        </Button>
        <Text
          w="full"
          textAlign="center"
          fontSize="xs"
          data-testid="forgot-password"
        >
          Forgot Password?{" "}
          <Link
            to={paths.RECOVER_PASSWORD}
            className="text-primary font-semibold"
          >
            Recover
          </Link>
        </Text>
        <Oauth />
      </Box>
    </Box>
  );
};

export default Login;
