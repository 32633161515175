import axios from "axios";

export default class HttpService {
  axiosInstance;
  constructor(params) {
    this.axiosInstance = axios.create(params);
  }

  async resolver(fn) {
    let data = null;
    let error = null;
    try {
      const { data: apiResponse } = await fn;
      data = apiResponse;
    } catch (_error) {
      error = _error.response?.data || {
        message: _error.message,
        statusCode: 400,
      };
    }
    return { data, error };
  }

  async SendRequest(params) {
    return this.resolver(
      this.axiosInstance[params.method](
        params.path,
        params.body ? params.body : { params: params.query || {} },
        params.body && params.query ? { params: params.query } : {}
      )
    );
  }
}
