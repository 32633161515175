import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { LinkedIn } from "react-linkedin-login-oauth2";
import linkedinIcon from "../../assets/icons/skill-icons_linkedin.svg";
import microsoftIcon from "../../assets/icons/skill-icons_microsoft.svg";
import {
  Button,
  Stack,
  Box,
  Divider,
  AbsoluteCenter,
  useToast,
} from "@chakra-ui/react";
import { googleAuth } from "../../utils";
import { useLocation } from "react-router-dom";

const Oauth = () => {
  const toast = useToast();
  const { pathname } = useLocation();
  const startTextWith = pathname === "/signup" ? "signup_with" : "signin_with";
  return (
    <Box mt={6}>
      <Box position="relative" padding={3.5}>
        <Divider />
        <AbsoluteCenter bg="white" px="4">
          Or
        </AbsoluteCenter>
      </Box>
      <Stack
        pt={6}
        px={[6, 0]}
        spacing={4}
        direction={["column", "row"]}
        flexWrap="wrap"
        justifyContent="center"
      >
        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            const { data, error } = await googleAuth(
              credentialResponse.credential
            );
            if (data) {
              toast({
                title: "Welcome!",
                status: "success",
              });
            } else if (error) {
              toast({
                title: `Error ${
                  pathname === "/signup" ? "Signing up" : "Signing In"
                } `,
                status: "error",
              });
            }
          }}
          onError={() => {
            toast({
              title: `${
                pathname === "/signup" ? "Signup" : "Signin"
              } Unssucessful`,
              status: "error",
            });
          }}
          text={`${startTextWith}`}
          logo_alignment="center"
        />
        <LinkedIn
          clientId="77n0e3zaqv3zzr"
          redirectUri={`http://localhost:3000/login`}
          onSuccess={(code) => {
            console.log(code);
          }}
          onError={(error) => {
            console.log(error);
          }}
        >
          {({ linkedInLogin }) => (
            <Button
              leftIcon={<img src={linkedinIcon} alt="" />}
              variant="outline"
              fontSize="sm"
              onClick={linkedInLogin}
            >
              {pathname === "/signup" ? "Sign up" : "Sign in"} with LinkedIn
            </Button>
          )}
        </LinkedIn>
        <Button
          leftIcon={<img src={microsoftIcon} alt="" />}
          variant="outline"
          fontSize="sm"
        >
          {pathname === "/signup" ? "Sign up" : "Sign in"} with Microsoft
        </Button>
      </Stack>
    </Box>
  );
};

export default Oauth;
