import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SettingsChangeEmailConfirmation from "../../components/Modals/SettingsChangeEmailConfirmation";

const ChangeEmail = () => {
  const [tokenSent, setTokenSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Card w="full" maxW="3xl" px={{ base: 2, md: 5 }} py={6}>
        <CardHeader>
          <Heading as="h6" size="sm">
            Change Email Address
          </Heading>
          <Text mt={5} fontSize="sm">
            {tokenSent
              ? "An  email has been sent to your email address. Check the inbox of your email account for the verification token provided."
              : "To change your email address, a token will be sent to your registered email address"}
          </Text>
        </CardHeader>
        <CardBody>
          {!tokenSent && (
            <Box as="form" maxW="sm" onSubmit={() => setTokenSent(true)}>
              <FormControl>
                <FormLabel>Email Address</FormLabel>
                <Input />
              </FormControl>

              <Button type="submit" variant="brandPrimary" mt={10}>
                Send token
              </Button>
            </Box>
          )}
          {tokenSent && !verified && (
            <Box as="form" maxW="sm" onSubmit={() => setVerified(true)}>
              <FormControl>
                <FormLabel>Verificaton Token</FormLabel>
                <Input />
              </FormControl>

              <Button type="submit" variant="brandPrimary" mt={10}>
                Verify
              </Button>
              <Text mt={5} fontSize="sm" textAlign="center" fontWeight="medium">
                Didn’t receive an email?{" "}
                <Text as="span" color="primary.500">
                  Retry
                </Text>
              </Text>
            </Box>
          )}
          {verified && (
            <Box as="form" maxW="sm">
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input />
              </FormControl>

              <Button variant="brandPrimary" mt={10} onClick={onOpen}>
                Confirm
              </Button>
            </Box>
          )}
        </CardBody>
      </Card>
      <SettingsChangeEmailConfirmation isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default ChangeEmail;
