import React from "react";
import UserList from "../../components/User/UserList";
import EmptyState from "../../components/Followers/EmptyState";
import { useQuery } from "@tanstack/react-query";
import { FollowApi } from "../../service/follow/FollowApi.service";

const Following = () => {
  const {
    data: { data: { followers } } = { data: { data: [] } },
    isLoading,
    error,
  } = useQuery({
    queryKey: ["following"],
    queryFn: () => FollowApi.GetFollowing(),
  });
  return (
    <>
      <UserList
        isLoading={isLoading}
        users={followers}
        title="Following"
        error={error}
        emptyState={
          <EmptyState
            header="You are not following anyone at the moment"
            instructions="Not to worry we have suggested a list of people you can follow based
        on your goals."
          />
        }
      />
    </>
  );
};

export default Following;
