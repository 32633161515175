import React from "react";
import { Box, Link, Text } from "@chakra-ui/react";

const YourAccount = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        The Terms and Conditions (
        <Text as="span" fontWeight={500}>
          “Terms”
        </Text>
        ) describe how {" "}
        <Text as="span" fontWeight={500}>
          Hinterviews (“Company,” “we,” and “our”)
        </Text>
         regulates your use of this{" "}
        <Link href="http://hinterviews.com" color="#0A66C2">
          website
        </Link>{" "}
        (the “Website”). Please read the following information carefully to
        understand our practices regarding your use of the website. The Company
        may change the Terms at any time. The Company may inform you of the
        changes to the Terms using the available means of communication. The
        Company recommends you to check the website frequently to see the actual
        version of the Terms and their previous versions. If you represent a
        legal entity, you certify that you entitled by such a legal entity to
        conclude the Terms as the legal entity you represent.
      </Text>

      <Text w="100%" lineHeight={1.5} my="20px">
        When using the website, you shall be responsible for ensuring the
        confidentiality of your account, password and other credentials and for
        secure access to your device. You shall not assign your account to
        anyone. The Company is not responsible for unauthorized access to your
        account that results from misappropriation or theft of your account. The
        Company may refuse or cancel service, terminate your account, and remove
        or edit content.
        <br /> <br /> The Company does not knowingly collect personal data from
        persons under the age of 16 (sixteen). If you are under 16 (sixteen)
        years old, you may not use the website and may not enter into the Terms
        under any circumstances.
      </Text>
    </Box>
  );
};

export default YourAccount;
