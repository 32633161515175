import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Heading,
  Text,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "../../../service/auth/AuthApi.service";
import { paths } from "../../../routes";

const Verification = () => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutate: handleVerification } = useMutation({
    mutationFn: ({ token }) => AuthApi.VerifyAccount({ userId: id, token }),
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: ({ data, error }) => {
      setLoading(false);
      if (data) {
        navigate(paths.LOGIN);
      } else if (error) {
        toast({
          title: error.defaultMessage,
          status: "error",
        });
      }
    },
    onError: (err) => {
      setLoading(false);
      toast({
        title: err.defaultMessage
          ? err.defaultMessage
          : "There is a problem, try again",
        status: "error",
      });
    },
  });
  const {
    getFieldProps,
    errors,
    touched,
    values: user,
    handleSubmit,
  } = useFormik({
    initialValues: {
      token: "",
    },
    onSubmit: (token) => {
      handleVerification(token);
    },
    validationSchema: Yup.object().shape({
      token: Yup.string().required("Required"),
    }),
  });

  useEffect(() => {
    if (user.token.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [user]);
  return (
    <Box>
      <Heading mb={4} fontWeight={600} fontSize="3.5xl">
        Account Verification
      </Heading>
      <Text fontSize="sm">
        An email has been sent to your email address. Check the inbox of your
        email account for the verification token provided.
      </Text>
      <Box as="form" onSubmit={handleSubmit}>
        <FormControl my={10} isInvalid={!!errors.token && touched.token}>
          <FormLabel htmlFor="token">Verfication Token</FormLabel>
          <Input variant="outline" type="text" {...getFieldProps("token")} />
          <FormErrorMessage>{errors.token}</FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          variant="brandPrimary"
          my={8}
          isDisabled={buttonDisabled}
          isLoading={loading}
        >
          Verify
        </Button>
      </Box>
      <Text w="full" textAlign="center" fontSize="sm" mt={5} fontWeight={500}>
        Didn't receive an email or SMS?{" "}
        <Text as="span" color="primary.500">
          Retry
        </Text>
      </Text>
    </Box>
  );
};

export default Verification;
