import React from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  Hide,
  Icon,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ChevronIcon, PencilIcon, StarIcon } from "../Icons";
import Steps from "./Steps";
import { paths } from "../../routes";
import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProfileApi } from "../../service/profile/ProfileApi.service";

const Milestone = (props) => {
  const { pathname } = useLocation();
  const {
    id,
    organization,
    endDate,
    likes,
    startDate,
    title,
    userGoalSteps,
    value,
  } = props;
  const queryClient = useQueryClient();

  const { mutate: upvote, isPending } = useMutation({
    mutationFn: async () =>
      await ProfileApi.UpvoteUserGoal({
        goalId: id,
      }),
    onSuccess: ({ data, error }) => {
      queryClient.invalidateQueries({
        queryKey: ["userGoals"],
      });
    },
  });

  const handleEditClick = () => {
    console.log("clicked");
    const { handleEdit } = props;
    handleEdit(id);
  };

  const UpvoteBtn = () => {
    return (
      <Box
        as="button"
        bgColor="green.500"
        pos="absolute"
        zIndex={4}
        right={{ md: 36, lg: "unset", xl: 36 }}
        top={{ md: 8, lg: "unset", xl: 5 }}
        color="white"
        py={1}
        px={3}
        borderRadius="lg"
        fontSize="sm"
        fontWeight={600}
        display="flex"
        alignItems="center"
        gap={1}
        bottom={{ base: -3, md: "unset", lg: -3, xl: "unset" }}
        left={{ base: 1, md: "unset", lg: 1, xl: "unset" }}
        onClick={upvote}
      >
        {isPending ? (
          <Spinner />
        ) : (
          <>
            <StarIcon />
            Upvote
          </>
        )}
      </Box>
    );
  };
  return (
    <AccordionItem border="none" pos="relative" h="max-content">
      {({ isExpanded }) => (
        <>
          <AccordionButton
            _hover={{ bgColor: "transparent" }}
            p={0}
            alignItems="flex-start"
            w="full"
          >
            {isExpanded ? (
              <ChevronIcon boxSize={6} color="secondary.500" mt={1} />
            ) : (
              <ChevronIcon
                boxSize={6}
                color="secondary.500"
                transform="rotate(-90deg)"
                mt={1}
              />
            )}

            <Flex
              justifyContent="space-between"
              alignItems="center"
              w="full"
              maxW="90%"
            >
              <Flex gap={6} ml={{ base: 2, lg: 4 }}>
                <Stack spacing={0.5}>
                  <Text fontWeight={500} color="grey.900" textAlign="left">
                    {organization}
                  </Text>
                  <Text fontSize="sm" fontStyle="italic" color="grey.500">
                    {startDate} - {!!endDate ? endDate : "Present"}
                  </Text>
                  <Badge variant="achieved" mt={1.5}>
                    {title || value}
                  </Badge>
                </Stack>
                <Flex
                  as="p"
                  alignSelf="start"
                  alignItems="center"
                  color="green.500"
                >
                  <Icon as={StarIcon} /> <Text as="span">{likes}</Text>
                </Flex>
              </Flex>
              {pathname === paths.PROFILE && (
                <PencilIcon
                  color="#344054"
                  w={6}
                  h={6}
                  onClick={() => handleEditClick()}
                />
              )}
            </Flex>
          </AccordionButton>
          <AccordionPanel ml={{ base: 4, lg: 6 }} pb={0}>
            <Hide below="xl">
              {isExpanded && pathname !== paths.PROFILE && <UpvoteBtn />}
            </Hide>
            {userGoalSteps && <Steps steps={userGoalSteps} />}
          </AccordionPanel>
          <Hide above="xl">
            {isExpanded && (
              <Box mb={6}>
                <UpvoteBtn />
              </Box>
            )}
          </Hide>
        </>
      )}
    </AccordionItem>
  );
};

export default Milestone;
