import React from "react";
import { Box, Text } from "@chakra-ui/react";

const Indemnification = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        You agree to indemnify, defend and hold harmless the Company, its
        managers, directors, employees, agents, and third parties, for any
        costs, losses, expenses (including attorneys’ fees), liabilities
        regarding or arising out of your enjoyment of or inability to enjoy the
        website or its services and Company’s services and products, your
        violation of the Terms or your violation of any rights of third parties,
        or your violation of the applicable law. The Company may assume the
        exclusive defense and you shall cooperate with the Company in asserting
        any available defenses.
      </Text>
    </Box>
  );
};

export default Indemnification;
