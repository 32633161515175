import {
  Box,
  Center,
  Heading,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Notification from "../../components/Notification";
import { notifications } from "../../mockDB";

const Notifications = () => {
  return (
    <Box w="full">
      <Heading as="h6" size={{ base: "sm", md: "md" }}>
        Notifications
      </Heading>

      <Stack
        my={{ base: 6, md: 10 }}
        divider={<StackDivider borderColor="gray.200" my="24px !important" />}
      >
        {!!notifications &&
          notifications.length > 0 &&
          notifications.map((notification) => (
            <Notification key={notification.id} {...notification} />
          ))}
        {!!notifications && notifications.length === 0 && (
          <Center height="200px">
            <Text>No notifications yet! </Text>
          </Center>
        )}
      </Stack>
    </Box>
  );
};

export default Notifications;
