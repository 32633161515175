import React from "react";
import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";

const SharingData = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        We may share your personal data as follows:
      </Text>

      <UnorderedList display="flex" flexDirection="column" gap={3}>
        <ListItem>
          <Text w="100%" fontWeight={500} as="span">
            Third Parties Designated by You.
          </Text>
           We may share your personal data with third parties where you have
          provided your consent to do so.
        </ListItem>
        <ListItem>
          <Text w="100%" fontWeight={500} as="span">
            Our Third Party Service Providers.
          </Text>
          We may share your personal data with our third party service providers
          who provide services such as data analysis, payment processing,
          information technology and related infrastructure provision, customer
          service, email delivery, auditing and other similar services.
        </ListItem>
        <ListItem>
          <Text w="100%" fontWeight={500} as="span">
            Third Party Sites
          </Text>
        </ListItem>
      </UnorderedList>

      <Text w="100%" lineHeight={1.5} my="20px">
        Our website may contain links to third party websites and features. This
        Policy does not cover the privacy practices of such third parties. These
        third parties have their own privacy policies and we do not accept any
        responsibility or liability for their websites, features or policies.
        Please read their privacy policies before you submit any data to them.
      </Text>
    </Box>
  );
};

export default SharingData;
