import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import React from "react";

const NotificationSetting = () => {
  return (
    <Card w="full" maxW="3xl" px={{ base: 2, md: 5 }} py={6}>
      <CardHeader>
        <Box>
          <Heading as="h6" size="sm">
            Notification Setting
          </Heading>
          <Text mt={5}>Change your email notification setting here. </Text>
        </Box>
      </CardHeader>
      <CardBody>
        <Stack>
          <Flex w="full" justifyContent="space-between">
            <Box>
              <Text fontSize="sm" fontWeight="semibold">
                Email Notification
              </Text>
              <Text>Turn on and off email notifications</Text>
            </Box>
            <Switch />
          </Flex>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default NotificationSetting;
