import {
  Box,
  Grid,
  GridItem,
  Hide,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { companies } from "../../mockDB";
import CompanyCard from "../../components/Companies/CompanyCard";
import { SearchIcon } from "../../components/Icons";

const CompanyList = ({ header, hideHeader }) => {
  return (
    <Box w="full">
      <Box maxW="3xl">
        <Stack>
          {hideHeader ? (
            <Hide above="lg">
              <Text
                textAlign="left"
                order={{ base: 1, lg: 2 }}
                fontWeight={600}
                color="grey.800"
              >
                {header}
              </Text>
            </Hide>
          ) : (
            <Text
              textAlign="left"
              order={{ base: 1, lg: 2 }}
              fontWeight={600}
              color="grey.800"
              mb={{ lg: 6 }}
              fontSize={{ lg: "lg" }}
            >
              {header}
            </Text>
          )}

          <InputGroup
            w="60%"
            maxW="sm"
            order={{ base: 2, lg: 1 }}
            mt={{ base: 6, lg: 0 }}
            mb={{ base: 6, lg: 10 }}
          >
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="lg"
            >
              <SearchIcon />
            </InputLeftElement>
            <Input placeholder="Search" px={{ lg: 20 }} borderRadius="3xl" />
          </InputGroup>
        </Stack>

        <Grid
          autoRows="max-content"
          templateColumns={{
            md: "repeat(2,1fr)",
          }}
          columnGap={6}
          rowGap={8}
          w="full"
        >
          {companies
            .filter((company) => !!company.following)
            .map((data) => (
              <GridItem key={data.id}>
                <CompanyCard
                  boxShadow="0px 10px 18px -2px rgba(16, 25, 40, 0.07)"
                  p={3}
                  borderRadius="lg"
                  {...data}
                />
              </GridItem>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CompanyList;
