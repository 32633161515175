import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Heading,
  Hide,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import homeImg from "../../assets/images/homeImg.svg";
import { Image } from "@chakra-ui/react";
import homeBg from "../../assets/images/homeBg.svg";
import {
  CloseIcon,
  CommunityIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  MenuIcon,
  ProfileIcon,
  TwitterIcon,
  UserConnectIcon,
} from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes";
import { isLive } from "../../utils";

const Home = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const isOnProduction = isLive();
  return (
    <>
      <Flex
        as="nav"
        w="full"
        justifyContent="space-between"
        my={{ md: 10 }}
        px={{ base: 5, lg: 14 }}
        py={{ base: 6, md: 0 }}
        pos="relative"
      >
        <Text
          color="#0D5EBA"
          fontSize={{ base: "xl", lg: "3xl" }}
          fontWeight="bold"
          data-testid="logo-text"
        >
          hinterviews
        </Text>
        {isOnProduction ? (
          <Button variant="brandPrimary" w="fit-content">
            <a
              href="https://forms.office.com/r/zXhPiwM915"
              target="_blank"
              rel="noreferrer"
            >
              Join the WaitList
            </a>
          </Button>
        ) : (
          <Flex alignItems="center" gap={4} hideBelow="md">
            <Button variant="outline" onClick={() => navigate(paths.LOGIN)}>
              Login
            </Button>
            <Button
              variant="brandPrimary"
              w="fit-content"
              py={4}
              onClick={() => navigate(paths.SIGNUP)}
            >
              Get Started
            </Button>
          </Flex>
        )}
        <Hide above="md">
          <Stack>
            {!showMenu && (
              <MenuIcon
                role="menubutton"
                aria-label="open menu button"
                onClick={() => setShowMenu(true)}
              />
            )}
            {showMenu && (
              <CloseIcon
                role="menubutton"
                aria-label="close menu button"
                onClick={() => setShowMenu(false)}
              />
            )}
            {showMenu &&
              (isOnProduction ? (
                <Button variant="brandPrimary" w="fit-content">
                  <a
                    href="https://forms.office.com/r/zXhPiwM915"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Join the WaitList
                  </a>
                </Button>
              ) : (
                <Box
                  position="absolute"
                  left={0}
                  right={0}
                  minH="400px"
                  bgColor="white"
                  top="70px"
                  zIndex="popover"
                  role="menu"
                >
                  <Divider></Divider>
                  <Stack alignItems="center" gap={10} mt={16} w="80%" mx="auto">
                    <Button
                      variant="outline"
                      onClick={() => navigate(paths.LOGIN)}
                    >
                      Login
                    </Button>
                    <Button
                      variant="brandPrimary"
                      w="fit-content"
                      py={4}
                      onClick={() => navigate(paths.SIGNUP)}
                    >
                      Get Started
                    </Button>
                  </Stack>
                </Box>
              ))}
          </Stack>
        </Hide>
      </Flex>
      <Box
        as="header"
        bgImage={`${homeBg}`}
        mt={{ base: 6, lg: 0 }}
        data-testid="background"
      >
        <Flex
          maxWidth={{ md: "90%", lg: "83%" }}
          mx="auto"
          alignItems="center"
          justifyContent="space-between"
          flexDir={{ base: "column", md: "row" }}
          px={{ base: 5 }}
        >
          <Stack maxW="590px" spacing={{ base: 4, lg: 8 }}>
            <Heading
              as="h1"
              fontSize={{ base: "2xl", lg: "5xl" }}
              noOfLines={{ lg: 2 }}
              fontWeight="semibold"
              letterSpacing="-1.92px"
              lineHeight={{ base: "2rem", lg: "3.9rem" }}
              data-testid="heading"
            >
              Connect and Engage With Like-Minded Experts
            </Heading>
            <Text
              maxW="450px"
              fontSize={{ lg: "lg" }}
              data-testid="sub-heading"
            >
              Achieve your career goals faster by connecting with experts in
              your field. Engage in insightful conversations, and access
              invaluable career advice.
            </Text>
            {!isOnProduction ? (
              <Flex
                alignItems={{ lg: "center" }}
                gap={4}
                flexDir={{ base: "column", lg: "row" }}
                mt={{ base: 2, lg: 0 }}
              >
                <Button
                  variant="brandPrimary"
                  minWidth="180px"
                  py={6}
                  w="fit-content"
                  onClick={() => navigate(paths.SIGNUP)}
                >
                  Get Started
                </Button>
                <Text fontWeight={500} fontSize={{ base: "sm", md: "md" }}>
                  Already have an account?
                  <Link
                    href={paths.LOGIN}
                    ml={2}
                    color="#0D5EBA"
                    fontWeight={600}
                    data-testid="login"
                  >
                    Log in{" "}
                  </Link>
                </Text>
              </Flex>
            ) : (
              <Button variant="brandPrimary" w="fit-content">
                <a
                  href="https://forms.office.com/r/zXhPiwM915"
                  target="_blank"
                  rel="noreferrer"
                >
                  Join the WaitList
                </a>
              </Button>
            )}
          </Stack>
          <Box px={{ base: 3 }}>
            <Image src={homeImg} alt="Home Image" />
          </Box>
        </Flex>
      </Box>
      <Box w="full" mt={{ lg: 24 }}>
        <Flex
          w="full"
          flexDir={{ base: "column", md: "row" }}
          justifyContent="center"
          gap={{ base: 6, lg: 12 }}
          px={{ base: 6, lg: 0 }}
          mt={{ base: 12 }}
        >
          <Stack
            bgColor="#FFECE5"
            h="fit-content"
            py={4}
            px={6}
            textAlign="center"
            spacing={1}
            w={{ lg: "max-content" }}
            alignItems="center"
          >
            <Text
              fontWeight="semibold"
              fontSize="xl"
              letterSpacing="tighter"
              lineHeight="8"
            >
              DISCOVER THE WORLD WITH US
            </Text>
            <Text
              maxW={{ lg: "80%" }}
              fontSize={{ base: "xs" }}
              color="#475367"
            >
              For those who want to achieve their career goals faster done the
              right way
            </Text>
          </Stack>
          <Box mt={{ md: 5 }} pos="relative">
            <Box
              h={12}
              w={16}
              borderLeft="4px"
              borderTop="4px"
              pos="absolute"
              left="-3.5"
              borderRadius="4px"
              style={{ borderColor: "#1671D9" }}
            ></Box>
            <Text
              maxW="500px"
              fontSize={{ base: "sm", lg: "md" }}
              fontWeight="medium"
              color="#475367"
              px={{ lg: 4 }}
              py={4}
              lineHeight="1.6rem"
              letterSpacing="-0.04rem"
            >
              Through our user-friendly interface, users can connect with
              like-minded experts, engage in insightful conversations, and
              access invaluable career advice.{" "}
              <Text as="span" bgColor="#C6DDF7">
                Our mission is to empower individuals to share knowledge,
                exchange insights, and build valuable networks. {""}
              </Text>
              Whether you’re a seasoned professional or just starting your
              journey, our platform provides a safe, collaborative space for
              knowledge exchange. Join us today and embark on a transformative
              learning experience that will elevate your professional growth to
              new heights.
            </Text>
            <Box
              h={12}
              w={16}
              borderColor="#1671D9"
              borderRight="4px"
              borderBottom="4px"
              pos="absolute"
              right="-3.5"
              bottom={0}
              borderRadius="4px"
              style={{ borderColor: "#1671D9" }}
            ></Box>
          </Box>
        </Flex>
        <Stack
          as="section"
          w="full"
          alignItems="center"
          mt={{ base: 10, lg: 20 }}
          mb={{ base: 20, lg: 20 }}
          px={{ base: 6 }}
        >
          <Heading
            as="h6"
            fontSize={{ base: "md", lg: "xl" }}
            letterSpacing="tighter"
            textAlign="center"
            fontWeight="semibold"
          >
            TAKING YOU ON GREAT CAREER JOURNEYS
          </Heading>
          <Text
            textAlign="center"
            fontSize={{ base: "sm", lg: "md" }}
            fontWeight="medium"
            letterSpacing="-0.56px"
            lineHeight="22.4px "
          >
            No more endless DMs and coffee chats to uncover dream job secrets
          </Text>
          <Flex
            flexDir={{ base: "column", md: "row" }}
            rowGap={7}
            columnGap={{ md: 8, lg: "50px" }}
            px={{ base: 1.5 }}
            maxW="6xl"
            mx="auto"
            mt={{ base: 8, lg: 16 }}
          >
            <Card px={4} py={5} pos="relative">
              <Box
                h={12}
                w={16}
                borderLeft="4px"
                borderTop="4px"
                pos="absolute"
                left="-2.5"
                borderRadius="20px 4px 4px 4px"
                top="-8px"
                style={{ borderColor: "#1671D9" }}
              ></Box>
              <Stack spacing={3}>
                <Flex
                  bgColor="#E3EFFC"
                  h={8}
                  w={8}
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                >
                  <UserConnectIcon
                    w={{ base: 4, lg: 5 }}
                    h={{ base: 4, lg: 5 }}
                  />
                </Flex>
                <Stack spacing={2}>
                  <Text fontWeight="medium" fontSize={{ base: "md", lg: "lg" }}>
                    Social
                  </Text>
                  <Text>
                    Connect with like-minded professionals to learn how they
                    achieved their goals
                  </Text>
                </Stack>
              </Stack>
            </Card>
            <Card px={4} py={5}>
              <Box
                h={12}
                w={16}
                borderLeft="4px"
                borderTop="4px"
                pos="absolute"
                left="-2.5"
                borderRadius="20px 4px 4px 4px"
                top="-8px"
                style={{ borderColor: "#F56630" }}
              ></Box>
              <Stack spacing={3}>
                <Flex
                  bgColor="#FFECE5"
                  h={8}
                  w={8}
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                >
                  <CommunityIcon
                    w={{ base: 4, lg: 5 }}
                    h={{ base: 4, lg: 5 }}
                  />
                </Flex>
                <Stack spacing={2}>
                  <Text fontWeight="medium" fontSize={{ base: "md", lg: "lg" }}>
                    Community
                  </Text>
                  <Text>
                    Get instant answers about your career goals from a community
                    of experts.
                  </Text>
                </Stack>
              </Stack>
            </Card>
            <Card px={4} py={5}>
              <Box
                h={12}
                w={16}
                borderLeft="4px"
                borderTop="4px"
                pos="absolute"
                left="-2.5"
                borderRadius="20px 4px 4px 4px"
                top="-8px"
                style={{ borderColor: "#0F973D" }}
              ></Box>
              <Stack spacing={3}>
                <Flex
                  bgColor="#E7F6EC"
                  h={8}
                  w={8}
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                >
                  <ProfileIcon w={{ base: 4, lg: 5 }} h={{ base: 4, lg: 5 }} />
                </Flex>
                <Stack spacing={2}>
                  <Text fontWeight="medium" fontSize={{ base: "md", lg: "lg" }}>
                    Profile
                  </Text>
                  <Text>
                    Learn how other professionals achieved similar goals without
                    hassling anyone
                  </Text>
                </Stack>
              </Stack>
            </Card>
          </Flex>
          {!isOnProduction ? (
            <Flex w={{ base: "85%", sm: "30%", md: "20%" }} mx="auto">
              <Button
                variant="brandPrimary"
                mt={{ base: 10, lg: 12 }}
                onClick={() => navigate("/signup")}
              >
                Get Started
              </Button>
            </Flex>
          ) : (
            <Button variant="brandPrimary" w="fit-content">
              <a
                href="https://forms.office.com/r/zXhPiwM915"
                target="_blank"
                rel="noreferrer"
              >
                Join the WaitList
              </a>
            </Button>
          )}
        </Stack>

        <Box
          as="footer"
          mt={{ lg: 30 }}
          bgColor="#034592"
          w="full"
          color="white"
          data-testid="footer"
        >
          <Flex
            maxW={{ lg: "85%" }}
            mx="auto"
            py={12}
            px={{ base: 10, lg: 0 }}
            justifyContent="space-between"
            flexDir={{ base: "column", lg: "row" }}
            rowGap={12}
          >
            <Stack
              spacing={6}
              alignItems={{ base: "center", lg: "flex-start" }}
            >
              <Heading as="h3" fontSize="32px" color="#E3EFFC">
                hinterviews
              </Heading>
              <Text
                maxW={{ lg: "xs" }}
                fontSize={{ base: "xs", lg: "sm" }}
                textAlign={{ base: "center", lg: "left" }}
              >
                Advance your career on our professional networking platform.
                Connect with industry leaders, forge meaningful connections, and
                stay updated on the latest trends.
              </Text>
              <Flex
                role="navigation"
                alignItems="center"
                gap={5}
                data-testid="social-urls"
              >
                <a
                  target="_blank"
                  aria-label="open hinterview's facebook page"
                  href="https://www.facebook.com/hinterviews"
                  rel="noreferrer"
                >
                  <FacebookIcon height={6} width={6} />
                </a>
                <a
                  target="_blank"
                  aria-label="open hinterview's twitter page"
                  href="https://twitter.com/myhinterviews"
                  rel="noreferrer"
                >
                  <TwitterIcon height={6} width={6} />
                </a>
                <a
                  target="_blank"
                  aria-label="open hinterview's instagram page"
                  href="https://instagram.com/hinterviews"
                  rel="noreferrer"
                >
                  <InstagramIcon height={6} width={6} />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/hinterviews/"
                  aria-label="open hinterview's linkedin page"
                  rel="noreferrer"
                >
                  <LinkedInIcon height={6} width={6} />
                </a>
              </Flex>
            </Stack>
            <Stack spacing={5} alignSelf="center">
              <Link
                href="/terms-of-services"
                _hover={{
                  textDecoration: "none",
                }}
              >
                Terms of Service
              </Link>
              <Link
                href="/privacy-policy"
                _hover={{
                  textDecoration: "none",
                }}
              >
                Privacy Policy
              </Link>
            </Stack>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default Home;
