import React from "react";
import {
  Box,
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
} from "@chakra-ui/react";

const Cookies = () => {
  return (
    <Box w="100%" my="40px">
      <Heading fontSize="16px" my="20px">
        What are cookies?
      </Heading>

      <Text w="100%" lineHeight={1.5} my="20px">
        We may collect information using “cookies.” Cookies are small data files
        stored on the hard drive of your computer or mobile device by a website.
        We may use both session cookies (which expire once you close your web
        browser) and persistent cookies (which stay on your computer or mobile
        device until you delete them) to provide you with a more personal and
        interactive experience on our website. <br />
        <br />
        We use two broad categories of cookies: (1) first party cookies, served
        directly by us to your computer or mobile device, which are used only by
        us to recognize your computer or mobile device when it revisits our
        website; and (2) third party cookies, which are served by service
        providers on our website, and can be used by such service providers to
        recognize your computer or mobile device when it visits other websites.
      </Text>

      <Heading fontSize="16px" my="20px">
        Cookies we use
      </Heading>

      <Text w="100%" lineHeight={1.5} my="20px">
        Our website uses the following types of cookies for the purposes set out
        below:
      </Text>
      <Box maxW="100%" w="100%" fontSize="14px">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Type of cookie</Th>
                <Th>Purpose</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Essential Cookies</Td>
                <Td>
                  <Text w="100%" style={{ textWrap: "wrap" }}>
                    These cookies are essential to provide you with services
                    available through our website and to enable you to use some
                    of its features. For example, they allow you to log in to
                    secure areas of our website and help the content of the
                    pages you request load quickly.Without these cookies, the
                    services that you have asked for cannot be provided, and we
                    only use these cookies to provide you with those services.
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Td>Functionality Cookies</Td>
                <Td>
                  {" "}
                  <Text w="100%" style={{ textWrap: "wrap" }}>
                    These cookies allow our website to remember choices you make
                    when you use our website, such as remembering your language
                    preferences, remembering your login details and remembering
                    the changes you make to other parts of our website which you
                    can customize. The purpose of these cookies is to provide
                    you with a more personal experience and to avoid you having
                    to re-enter your preferences every time you visit our
                    website.
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Td>Analytics and Performance Cookies</Td>
                <Td>
                  <Text w="100%" style={{ textWrap: "wrap" }}>
                    These cookies are used to collect information about traffic
                    to our website and how users use our website. The
                    information gathered does not identify any individual
                    visitor. It includes the number of visitors to our website,
                    the websites that referred them to our website, the pages
                    they visited on our website, what time of day they visited
                    our website, whether they have visited our website before,
                    and other similar information. We use this information to
                    help operate our website more efficiently, to gather broad
                    demographic information and to monitor the level of activity
                    on our website. We use Google Analytics for this purpose.
                    Google Analytics uses its own cookies. It is only used to
                    improve how our website works. You can find out more
                    information about{" "}
                    <Link
                      href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies"
                      color="#0D5EBA"
                    >
                      Google Analytics cookies here.
                    </Link>
                    You can find out more about how{" "}
                    <Link
                      href="https://policies.google.com/privacy"
                      color="#0D5EBA"
                    >
                      Google protects your data here.
                    </Link>{" "}
                    You can prevent the use of Google Analytics relating to your
                    use of our website by downloading and installing the browser
                    plugin available{" "}
                    <Link
                      href="http://tools.google.com/dlpage/gaoptout?hl=en-GB"
                      color="#0D5EBA"
                    >
                      via this link.
                    </Link>{" "}
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <Heading fontSize="16px" my="20px">
        Disabling cookies
      </Heading>

      <Text w="100%" lineHeight={1.5} my="20px">
        You can typically remove or reject cookies via your browser settings. In
        order to do this, follow the instructions provided by your browser
        (usually located within the “settings,” “help” “tools” or “edit”
        facility). Many browsers are set to accept cookies until you change your
        settings.
        <br /> <br />
        If you do not accept our cookies, you may experience some inconvenience
        in your use of our website. For example, we may not be able to recognize
        your computer or mobile device and you may need to log in every time you
        visit our website.
      </Text>
    </Box>
  );
};

export default Cookies;
