import { Box, Card, Flex, Stack, StackDivider, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import FollowerCard from "../Followers/FollowerCard";
import CompanyCard from "../Companies/CompanyCard";
import { users as db, suggestedCompanies } from "../../mockDB";
import { Link, useLocation } from "react-router-dom";
import { paths } from "../../routes";
const Suggestions = () => {
  const location = useLocation();
  const show =
    location.pathname === paths.PROFILE || location.pathname === paths.USER;
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    setSuggestions(db.filter((user) => !user.following).slice(0, 2));
  }, []);
  return (
    <Box hideBelow={`${show ? "lg" : "md"}`}>
      <Card px={3} py={5} borderTopRadius="2xl">
        <Stack px={1}>
          <Flex w="full" justify="space-between" fontWeight={600} px={1}>
            <Text color="grey.800"> Who to follow</Text>
            <Text fontSize="sm" color="primary.400">
              See more
            </Text>
          </Flex>
          <Stack spacing={6} py={4}>
            {suggestions.map((data) => (
              <FollowerCard key={data.id} {...data} />
            ))}
          </Stack>

          <StackDivider />

          <Box>
            <Flex w="full" justify="space-between" fontWeight={600} px={1}>
              <Text color="grey.800">Suggested companies</Text>
              <Text fontSize="sm" color="primary.400">
                <Link to="/suggested-companies">See more</Link>
              </Text>
            </Flex>
            <Stack py={5} divider={<StackDivider />} spacing={5}>
              {suggestedCompanies.map((data) => (
                <CompanyCard key={data.id} {...data} />
              ))}
            </Stack>
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};

export default Suggestions;
