import HttpService from "..";
import { injectAuthorizationInterceptor } from "../../utils";

const jobsApiService = new HttpService({
  baseURL: "https://hinterview-backend-service.azurewebsites.net/api/jobs",
});
injectAuthorizationInterceptor(jobsApiService);

export const JobsApiService = {
  async GetJobs() {
    return jobsApiService.SendRequest({
      method: "get",
      path: ``,
    });
  },
};
