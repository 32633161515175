import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import PasswordInput from "../../components/Auth/PasswordInput";
import { Link } from "react-router-dom";
import { paths } from "../../routes";
import LoginModal from "../../components/Modals/LoginModal";

const SettingsChangePassword = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Card w="full" maxW="3xl" px={{ base: 2, md: 5 }} py={6}>
        <CardHeader>
          <Heading as="h6" size="sm">
            Change Password
          </Heading>
          <Text mt={5} fontSize="sm">
            Please provide your current password and choose a new password.
          </Text>
        </CardHeader>
        <CardBody>
          <Stack spacing={6} as="form" maxW="sm">
            <FormControl>
              <FormLabel>Current Password</FormLabel>
              <PasswordInput />
            </FormControl>
            <FormControl>
              <FormLabel>New Password</FormLabel>
              <PasswordInput />
            </FormControl>
            <FormControl>
              <FormLabel>Confirm Password</FormLabel>
              <PasswordInput />
            </FormControl>
            <Text fontSize="sm">
              Forgot Password?{" "}
              <Text
                as={Link}
                color="primary.500"
                to={paths.RECOVER_PASSWORD}
                fontWeight="semibold"
              >
                Recover
              </Text>
            </Text>
            <Button onClick={onOpen} variant="brandPrimary" mt={4}>
              Save password
            </Button>
          </Stack>
        </CardBody>
      </Card>
      <LoginModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default SettingsChangePassword;
