import React from "react";
import { Box, Text } from "@chakra-ui/react";

const Retention = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        We will only retain your personal data as long reasonably required for
        you to use the website until you close your account/cancel your
        subscription unless a longer retention period is required or permitted
        by law (for example for regulatory purposes).
      </Text>
    </Box>
  );
};

export default Retention;
