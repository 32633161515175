import React from "react";
import { Box, Text } from "@chakra-ui/react";

const ProhibitedUses = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        The Company grants you a non-transferable, non-exclusive, revocable
        license to access and use the website from one device in accordance with
        the Terms. You shall not use the website for unlawful or prohibited
        purpose. You may not use the website in a way that may disable, damage,
        or interfere in the website. <br /> <br />
        All content present on the website includes text, code, graphics, logos,
        images, compilation, software used on the website (hereinafter and
        hereinbefore the “Content“). The Content is the property of the Company
        or its contractors and protected by intellectual property laws that
        protect such rights. You agree to use all copyright and other
        proprietary notices or restrictions contained in the Content and you are
        prohibited from changing the Content.
        <br /> <br /> You may not publish, transmit, modify, reverse engineer,
        participate in the transfer, or create and sell derivative works, or in
        any way use any of the Content. Your enjoyment of the website shall not
        entitle you to make any illegal and disallowed use of the Content, and
        in particular you shall not change proprietary rights or notices in the
        Content. You shall use the Content only for your personal and
        non-commercial use. The Company does not grant you any licenses to the
        intellectual property of the Company.
      </Text>
    </Box>
  );
};

export default ProhibitedUses;
