import { extendTheme } from "@chakra-ui/react";
import { FormLabel } from "./components/FormLabel";
import { Stepper } from "./components/Stepper";
import { Badge } from "./components/Badge";
import { checkboxTheme, buttonTheme, linkTheme } from "./components/variants";
export const theme = extendTheme({
  components: {
    FormLabel,
    Checkbox: checkboxTheme,
    Button: buttonTheme,
    Link: linkTheme,
    Badge,
    Stepper,
  },
  colors: {
    green: {
      400: "#0F973D",
      500: "#099137",
    },
    grey: {
      75: "#F7F9FC",
      100: "#F0F2F5",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667185",
      600: "#475367",
      700: "#344054",
      800: "#1D2739",
      900: "#101928",
    },
    "office-brown": {
      700: "#645D5D",
    },
    primary: {
      50: "#E3EFFC",
      400: "#1671D9",
      500: "#0D5EBA",
      600: "#034592",
    },
    red: {
      400: "#D42620",
    },
    secondary: {
      50: "#FFECE5",
      500: "#EB5017",
    },
  },
  fontSizes: {
    "3.5xl": "2rem",
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
});
