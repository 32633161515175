import React from "react";
import { Box, Text } from "@chakra-ui/react";

const Complaints = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        We are committed to resolve any complaints about our collection or use
        of your personal data. If you would like to make a complaint regarding
        this Policy or our practices in relation to your personal data, please
        contact us through the information listed on our website. We will reply
        to your complaint as soon as we can and in any event, within 30 days. We
        hope to resolve any complaint brought to our attention, however if you
        feel that your complaint has not been adequately resolved, you reserve
        the right to contact your local data protection supervisory authority
      </Text>
    </Box>
  );
};

export default Complaints;
