import React from "react";
import { InputGroup, Input, Button, InputRightElement } from "@chakra-ui/react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

const PasswordInput = (props) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  return (
    <InputGroup size="md" alignItems="center">
      <Input
        variant="outline"
        type={show ? "text" : "password"}
        pr="4.5rem"
        {...props}
      />
      <InputRightElement width="2.5rem" bottom="0">
        <Button
          h="1.75rem"
          size="sm"
          bgColor="transparent"
          onClick={handleClick}
        >
          {show ? <IoEyeOffOutline size={20} /> : <IoEyeOutline size={20} />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
