import HttpService from "..";
import { injectAuthorizationInterceptor } from "../../utils";

const goalApiService = new HttpService({
  baseURL: "https://hinterview-backend-service.azurewebsites.net/api/goals",
});
injectAuthorizationInterceptor(goalApiService);

export const GoalsApi = {
  async GetGoals() {
    return goalApiService.SendRequest({
      method: "get",
      path: "",
    });
  },
};
