import React from "react";
import UserList from "../../components/User/UserList";
import { useQuery } from "@tanstack/react-query";
import { ProfileApi } from "../../service/profile/ProfileApi.service";
import EmptyState from "../../components/Followers/EmptyState";

const BasedOnGoal = () => {
  const { data: responseData, isLoading } = useQuery({
    queryKey: ["profile-by-goals"],
    queryFn: () => ProfileApi.GetProfilesByGoals(),
  });
  const { data: { profileDetails: profiles } = {}, error } = responseData || {};
  return (
    <>
      <UserList
        error={error}
        isLoading={isLoading}
        users={profiles}
        title="Based on Goals"
        emptyState={
          <EmptyState
            header="There are no users with you current goal"
            instructions="Ensure that you have updated you current goal to see users who have complete similar goals"
          />
        }
      />
    </>
  );
};

export default BasedOnGoal;
