import React from "react";
import { Box, Text, Heading, UnorderedList, ListItem } from "@chakra-ui/react";

const OurPolicyOnKids = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        Our website is/are not directed to children under 16. If a parent or
        guardian becomes aware that his or her child has provided us with
        information without their consent, he or she should contact us. We will
        delete such information from our files as soon as reasonably
        practicable.
      </Text>
      <Heading fontSize="16px" my="20px">
        Your Rights
      </Heading>

      <UnorderedList display="flex" flexDirection="column" gap={3}>
        <ListItem>
          <Text w="100%" fontWeight={500} as="span">
            Opt-out.
          </Text>
          You may contact us anytime to opt-out of: (i) direct marketing
          communications; (ii) automated decision-making and/or profiling; (iii)
          our collection of sensitive personal data; (iv) any new processing of
          your personal data that we may carry out beyond the original purpose;
          or (v) the transfer of your personal data outside the EEA. Please note
          that your use of some of the website may be ineffective upon opt-out.
        </ListItem>
        <ListItem>
          <Text w="100%" fontWeight={500} as="span">
            Access.
          </Text>
           You may access the information we hold about you at any time via your
          profile/account or by contacting us directly.
        </ListItem>
        <ListItem>
          <Text w="100%" fontWeight={500} as="span">
            Amend.
          </Text>
           You can also contact us to update or correct any inaccuracies in your
          personal data.
        </ListItem>
        <ListItem>
          <Text w="100%" fontWeight={500} as="span">
            Move. 
          </Text>
          Your personal data is portable – i.e. you to have the flexibility to
          move your data to other service providers as you wish.
        </ListItem>
        <ListItem>
          <Text w="100%" fontWeight={500} as="span">
            Erase and forget.
          </Text>
           In certain situations, for example when the information we hold about
          you is no longer relevant or is incorrect, you can request that we
          erase your data.
        </ListItem>
      </UnorderedList>

      <Text w="100%" lineHeight={1.5} my="20px">
        If you wish to exercise any of these rights, please contact us. In your
        request, please make clear: (i) what personal data is concerned; and
        (ii) which of the above rights you would like to enforce. For your
        protection, we may only implement requests with respect to the personal
        data associated with the particular email address that you use to send
        us your request, and we may need to verify your identity before
        implementing your request. We will try to comply with your request as
        soon as reasonably practicable and in any event, within one month of
        your request. Please note that we may need to retain certain information
        for recordkeeping purposes and/or to complete any transactions that you
        began prior to requesting such change or deletion.
      </Text>
    </Box>
  );
};

export default OurPolicyOnKids;
