import {
  Box,
  Container,
  Grid,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import onboardingImg from "../../assets/images/onboardingImg.png";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <Grid
      templateColumns={["repeat(1, 1fr)", null, null, "repeat(2, 1fr)"]}
      gap={4}
      p={[0, 5]}
      className="auth"
    >
      <Box pos="relative">
        <Box
          hideBelow="lg"
          h="full"
          borderRadius="3xl"
          bg="primary.600"
          py={10}
          px={16}
          color="white"
          _before={{
            content: '""',
            bgImage: "url(../../assets/images/onboardingBg.png)",
            bgSize: "cover",
            pos: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            opacity: 0.5,
            mixBlendMode: "overlay",
          }}
        >
          <Stack spacing={16}>
            <Heading as="h4" size="xl" fontWeight="bold" color="#E3EFFC">
              hinterviews
            </Heading>
            <Box>
              <Stack spacing={8}>
                <Heading
                  as="h1"
                  size={[null, null, null, "2xl", "3xl"]}
                  fontWeight={600}
                  lineHeight="4.2rem"
                >
                  Achieve your career goals faster
                </Heading>
                <Text
                  fontSize="md"
                  fontWeight={400}
                  lineHeight={6}
                  opacity="0.5"
                  maxW="sm"
                >
                  Achieve your career goals faster by connecting with experts in
                  your field. Engage in insightful conversations, and access
                  invaluable career advice.
                </Text>
              </Stack>
              <Box
                w="full"
                padding={4}
                bgColor="white"
                borderRadius="2xl"
                mt={[8, 12]}
              >
                <Image src={onboardingImg} alt="" w="full" />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>

      <Container
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxW="lg"
      >
        <Outlet />
      </Container>
    </Grid>
  );
};

export default AuthLayout;
