export const users = [
  {
    id: 1,
    username: "john-doe",
    avatar: "https://xsgames.co/randomusers/assets/avatars/male/5.jpg",
    firstName: "John",
    lastName: "Doe",
    job: "Software Engineer",
    upvoteCount: 42,
    company: "Tech Co",
    country: "United States",
    city: "San Francisco",
    followedBack: false,
    currentGoal: {
      id: "1",
      value: "Mentor 2 students",
    },
    owerCount: 200,
    followingCount: 60,
  },
  {
    id: 2,
    username: "jane-smith",
    avatar: "https://xsgames.co/randomusers/avatar.php?g=female",
    firstName: "Jane",
    lastName: "Smith",
    job: "UX Designer",
    upvoteCount: 28,
    company: "Design Studio",
    country: "United States",
    city: "New York",
    followedBack: false,
    currentGoal: {
      id: "1",
      value: "Write a book",
    },
    followerCount: 450,
    followingCount: 200,
  },

  {
    id: 3,
    username: "sara-jackson",
    avatar: "https://xsgames.co/randomusers/assets/avatars/female/18.jpg",
    firstName: "Sara",
    lastName: "Jackson",
    job: "Product Manager",
    upvoteCount: 19,
    company: "Tech Innovators",
    country: "United States",
    city: "Seattle",
    followedBack: true,
    currentGoal: {
      id: "1",
      value: "Get Promoted",
    },
    followerCount: 1000,
    followingCount: 1200,
  },
  {
    id: 4,
    username: "bob-jones",
    avatar: "https://xsgames.co/randomusers/assets/avatars/male/6.jpg",
    name: "Bob Jones",
    job: "Marketing Specialist",
    upvoteCount: 35,
    company: "Marketing Inc.",
    country: "United States",
    city: "Chicago",
    followedBack: true,
    currentGoal: {
      id: "1",
      value: "Foster a positive workplace culture",
    },
    owerCount: 500,
    followingCount: 480,
  },
  {
    id: 5,
    username: "alex-williams",
    avatar: "https://xsgames.co/randomusers/assets/avatars/female/15.jpg",
    firstName: "Alex",
    lastName: "Williams",
    job: "Data Scientist",
    upvoteCount: 50,
    company: "Data Insights",
    country: "United States",
    city: "Boston",
    followedBack: true,
    currentGoal: {
      id: "1",
      value: "Foster a positive workplace culture",
    },

    followerCount: 410,
    followingCount: 560,
  },
  {
    id: 6,
    username: "emily-morris",
    avatar: "https://xsgames.co/randomusers/assets/avatars/female/5.jpg",
    firstName: "Emily",
    lastName: "Morris",
    job: "Graphic Designer",
    upvoteCount: 33,
    company: "Creative Arts",
    country: "United States",
    city: "Los Angeles",
    followedBack: true,
    currentGoal: {
      id: "1",
      value: "Get Scholarship",
    },
    followerCount: 432,
    followingCount: 219,
  },
  {
    id: 7,
    username: "mark-anderson",
    avatar: "https://xsgames.co/randomusers/assets/avatars/male/25.jpg",
    firstName: "Mark",
    lastName: "Anderson",
    job: "Financial Analyst",
    upvoteCount: 27,
    company: "Finance Solutions",
    country: "United States",
    city: "Dallas",
    followedBack: false,
    currentGoal: "Build a framework",
    followerCount: 1678,
    followingCount: 1234,
  },
  {
    id: 8,
    username: "natalie-hernandez",
    avatar: "https://xsgames.co/randomusers/assets/avatars/female/23.jpg",
    firstName: "Natalie",
    lastName: "Hernandez",
    job: "HR Manager",
    upvoteCount: 40,
    company: "HR Experts",
    country: "United States",
    city: "Miami",
    followedBack: false,
    currentGoal: {
      id: "1",
      value: "Launch project",
    },
    followerCount: 420,
    followingCount: 420,
  },
];

export const user = {
  id: 28,
  username: "john-edward",
  avatar: "https://bit.ly/kent-c-dodds",
  firstName: "John",
  lastName: "Edwards",
  job: "Business Analyst",
  upvoteCount: 31,
  company: "Microsoft Corporation",
  country: "United States",
  city: "Chicago",
  followedBack: false,
  goal: "Switch Careers",
  followerCount: 40,
  followingCount: 60,
};

export const goals = [
  {
    id: 1,
    name: "Land a job promotion",
  },
  {
    id: 2,
    name: "Land a job",
  },
  {
    id: 3,
    name: "Land an internship",
  },
  {
    id: 4,
    name: "Land an academic scholarship",
  },
  {
    id: 5,
    name: "Switch career",
  },
];

export const followingCompany = [
  {
    id: 7,
    username: "john-doe",
    avatar: "https://xsgames.co/randomusers/assets/avatars/male/32.jpg",
    firstName: "John",
    lastName: "Doe",
    job: "Data Analyst",
    upvoteCount: 7,
    company: "Finance Solutions",
    country: "United States",
    city: "Chicago",
    followedBack: false,
    currentGoal: {
      id: "1",
      value: "New Job",
    },
    followerCount: 1678,
    followingCount: 1234,
  },
  {
    id: 8,
    username: "jane-doe",
    avatar: "https://xsgames.co/randomusers/assets/avatars/female/20.jpg",
    name: "Jane Doe",
    job: "UX Deisgner",
    upvoteCount: 40,
    company: "Pleo",
    country: "United States",
    city: "Chicago",
    followedBack: false,
    currentGoal: {
      id: "1",
      value: "Get a Promotion",
    },
    followerCount: 420,
    followingCount: 420,
  },
];

export const companies = [
  {
    id: "255",
    name: "Microsoft",
    followers: 600,
    following: false,
    image:
      "https://news.microsoft.com/wp-content/uploads/prod/sites/40/2022/11/Screenshot-2022-03-02-102510-1-960x640-2.jpg",
  },
  {
    id: "15",
    name: "Apple",
    followers: 1000,
    following: false,
    image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.jpg",
  },
  {
    id: "43",
    name: "Coursera",
    followers: 4560,
    following: true,
    image:
      "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera.s3.amazonaws.com/media/coursera-rebrand-logo-square.png?auto=format%2Ccompress&dpr=1",
  },
  {
    id: "63",
    name: "PWC",
    followers: 278,
    following: true,
    image: "https://jobberway.com/storage/pwc-symbol.png",
  },
  {
    id: "340",
    name: "Goldman Sachs",
    followers: 600,
    following: true,
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Goldman_Sachs.svg/1200px-Goldman_Sachs.svg.png",
  },
  {
    id: "89",
    name: "KPMG",
    followers: 567,
    following: true,
    image: "https://1000logos.net/wp-content/uploads/2023/03/KPMG-logo.png",
  },
  {
    id: "255",
    name: "EY",
    followers: 570,
    following: true,
    image:
      "https://cdn.icon-icons.com/icons2/2699/PNG/512/ey_logo_icon_171166.png",
  },
  {
    id: "21",
    name: "Deloitte",
    followers: 896,
    following: true,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUzrSPgn-8Rm62pmfQDusaPZZ0RHnt2vR2y_pZ4rwlH3q9N4AjbtlDOHC57XuMKOWwbIM&usqp=CAU",
  },
  {
    id: "87",
    name: "Google",
    followers: 739,
    following: true,
    image:
      "https://play-lh.googleusercontent.com/1-hPxafOxdYpYZEOKzNIkSP43HXCNftVJVttoo4ucl7rsMASXW3Xr6GlXURCubE1tA=w3840-h2160-rw",
  },
  {
    id: "33",
    name: "Meta",
    followers: 650,
    following: true,
    image: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Meta-Logo.png",
  },
];

export const suggestedCompanies = [
  {
    id: "255",
    name: "Microsoft",
    followers: 600,
    following: false,
    image:
      "https://news.microsoft.com/wp-content/uploads/prod/sites/40/2022/11/Screenshot-2022-03-02-102510-1-960x640-2.jpg",
  },
  {
    id: "15",
    name: "Apple",
    followers: 1000,
    following: false,
    image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.jpg",
  },
];

export const notifications = [
  {
    id: 2,
    user: "Jane Doe",
    action: "followed",
    goal: null,
    image: null,
  },
  {
    id: 5,
    user: "John Doe",
    action: "achievedGoal",
    goal: "Land a Promotion",
    image: null,
  },
  {
    id: 20,
    user: "Joseph James",
    action: "achievedGoal",
    goal: "Switch Careers",
    image: null,
  },
];

export const posts = [
  {
    id: 35,
    user: "Babatunde Olakunle",
    job: "Product Designer",
    date: "2 days ago",
    post: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione reiciendis consequuntur quasi quae? Sint impedit magnam soluta    laudantium iure officia debitis iste temporibus. Neque aliquid, earum labore dignissimos optio placeat!",
    likes: 42,
    comments: [
      {
        user: "Mary Folashade",
        job: "Frontend Developer",
        likes: 2,
        replies: 0,
        date: "3h ago",
        reply:
          " Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione reiciendis consequuntur quasi quae? Sint impedit magnam soluta    laudantium iure officia debitis iste temporibus. Neque aliquid, earum labore dignissimos optio placeat!",
      },
    ],
    shares: 2,
  },
  {
    id: 20,
    user: "Jane Does",
    job: "Business Analyst",
    date: "2 days ago",
    post: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione reiciendis consequuntur quasi quae? Sint impedit magnam soluta    laudantium iure officia debitis iste temporibus. Neque aliquid, earum labore dignissimos optio placeat!",
    likes: 35,
    comments: [],
    shares: 7,
  },
];

export const month = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "Feburary",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];
