import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { ProfileApi } from "../../../service/profile/ProfileApi.service";

const AddGoalStepModal = ({
  isOpen,
  onClose,
  userGoalId,
  currentGoalStep,
  handleGoalStep,
}) => {
  const [currStep, setCurrStep] = useState({ userGoalStepId: "", notes: "" });
  const { data: goalSteps } = useQuery({
    queryKey: ["goalsteps"],
    queryFn: () => ProfileApi.GetGoalStepsById({ userGoalId }),
    enabled: !!userGoalId,
  });

  useEffect(() => {
    if (currentGoalStep) {
      const step = {
        userGoalStepId: currentGoalStep.id,
        notes: currentGoalStep.notes ? currentGoalStep.notes : "",
      };
      setCurrStep(step);
    }
  }, [currentGoalStep]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent py={{ base: 6, lg: 10 }} borderRadius="20px">
        <ModalHeader>Add goal step</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={6}>
            <FormControl>
              <FormLabel>Goal step</FormLabel>
              <Select
                placeholder="Select"
                disabled={!!currentGoalStep}
                value={currStep?.userGoalStepId}
                onChange={(e) =>
                  setCurrStep(() => {
                    return {
                      ...currStep,
                      userGoalStepId: e.target.value,
                    };
                  })
                }
              >
                {!!goalSteps && !!goalSteps.data && (
                  <>
                    {goalSteps.data.values.map((step) => (
                      <option key={step.id} value={step.id}>
                        {step.value}
                      </option>
                    ))}
                  </>
                )}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Note (160 characters maximum)</FormLabel>
              <Textarea
                value={currStep?.notes}
                max={160}
                onChange={(e) =>
                  setCurrStep((prev) => {
                    return {
                      ...prev,
                      notes: e.target.value.slice(0, 160),
                    };
                  })
                }
              />
            </FormControl>
          </Stack>
          <Button mt={12} w="full" onClick={() => handleGoalStep(currStep)}>
            {currentGoalStep ? "Update" : "Add"}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddGoalStepModal;
