import { Grid, GridItem } from "@chakra-ui/react";
import React from "react";

const Skeleton = ({ number = 1, skeletonItem }) => {
  const array = Array.from({ length: number });
  return (
    <Grid
      autoRows="max-content"
      templateColumns={{
        xl: "repeat(2, 1fr)",
      }}
      columnGap={4}
      rowGap={6}
      w="full"
    >
      {array.map((_, index) => (
        <GridItem key={index}>{skeletonItem}</GridItem>
      ))}
    </Grid>
  );
};

export default Skeleton;
