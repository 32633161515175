import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";

const DeleteModal = ({ isOpen, onClose, handleDelete }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={{ base: 6 }}>
          <Text fontWeight="medium" mb={3} fontSize="lg">
            Are you sure you want to continue?
          </Text>
          <Flex gap={2} alignItems="center">
            <Button variant="outline" colorScheme="red" onClick={handleDelete}>
              Yes
            </Button>
            <Button variant="outline" onClick={() => onClose()}>
              No
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
