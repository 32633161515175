import { createContext, useContext } from "react";
import { CookiesProvider } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../../service/auth/AuthApi.service";
import { paths } from "../../routes";

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const handleLogin = async (user) => {
    try {
      const { data, error } = await AuthApi.Login(user);
      let response = {};
      if (data) {
        const prevLocation = localStorage.getItem("prevLocation");
        if (data.profileCompleted) {
          if (!!prevLocation) {
            navigate(prevLocation);
            localStorage.removeItem("prevLocation");
          } else {
            navigate(paths.COMMUNITY);
          }
        } else {
          navigate(paths.COMPLETE_PROFILE);
        }
        if (data.accessToken && data.refreshToken) {
          localStorage.setItem(
            "HINTERVIEW_USER_REFRESH_TOKEN",
            data.refreshToken
          );
          localStorage.setItem(
            "HINTERVIEW_USER_ACCESS_TOKEN",
            data.accessToken
          );
          response.data = data;
          response.error = null;
        } else {
          response.data = null;
          response.error = {
            defaultMessage: "Email not found",
            status: 400,
          };
        }
      }
      return { error, ...response };
    } catch (e) {
      console.log(e);
    }
  };

  const handleSignup = async (user) => {
    const { data, error } = await AuthApi.Signup(user);
    let response = {};
    if (data) {
      response.data = data;
      response.error = null;
      navigate(`/verify-account/${data.userId}`);
    } else {
      response.data = null;
      response.error = {
        defaultMessage: error.defaultMessage || "Error Signing In",
        status: 400,
      };
    }
    return { error, ...response };
  };

  return (
    <CookiesProvider>
      <AuthContext.Provider
        value={{
          isAuthorized: !!localStorage.getItem("HINTERVIEW_USER_ACCESS_TOKEN"),
          handleLogin,
          handleSignup,
        }}
      >
        {children}
      </AuthContext.Provider>
    </CookiesProvider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
