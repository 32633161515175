import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { ArrowLeftIcon } from "../../Icons";
import confirmationImg from "../../../assets/images/change-email-confirmation.svg";

const SettingsChangeEmailConfirmation = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ md: "lg" }}>
      <ModalOverlay />
      <ModalContent borderRadius="3xl">
        <ModalBody mb={14} maxW="md" mx="auto">
          <Flex flexDir="column" alignItems="center" my={8} w="full">
            <Image src={confirmationImg} alt="" />
            <Text>Your email address has been changed successfully </Text>
            <Button
              variant="brandPrimary"
              gap={2}
              w="max-content"
              mx="auto"
              mt={8}
              onClick={onClose}
            >
              {" "}
              <ArrowLeftIcon />
              Return to Settings
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SettingsChangeEmailConfirmation;
