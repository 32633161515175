import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { CommentIcon, LikeIcon, ReplyIcon, ShareIcon } from "../../Icons";

const Post = ({ user, date, post, likes, comments, shares, job }) => {
  const PostItem = ({ name, job, post, time }) => {
    return (
      <>
        <Flex
          flexDir={{ base: "column", md: "row" }}
          justifyContent="space-between"
          fontWeight="medium"
          px={6}
          rowGap={3}
        >
          <Flex gap={4} alignItems="center">
            <Avatar name={name} />
            <Box>
              <Text fontSize="sm" color="#1D2739">
                {name}
              </Text>
              <Text fontSize="xs" color="#667185">
                {job}
              </Text>
            </Box>
          </Flex>
          <Text alignSelf={{ md: "flex-end" }} fontSize="xs" color="#475467">
            Posted {time}
          </Text>
        </Flex>
        <Text px={6} fontSize="sm" lineHeight={5}>
          {post}
        </Text>
      </>
    );
  };
  const [showComment, setShowComment] = useState(false);
  return (
    <Stack spacing={6}>
      <PostItem name={user} job={job} post={post} time={date} />
      <Flex
        color="primary.500"
        justifyContent="space-between"
        px={6}
        bgColor="#F8F9FC"
        pt={2.5}
        pb={3}
      >
        <Flex gap={10}>
          <Stack spacing={1} alignItems="center">
            <LikeIcon />
            <Text fontSize="13px">{likes} Likes</Text>
          </Stack>

          <Stack spacing={1} alignItems="center">
            <IconButton
              icon={<CommentIcon color="primary.500" />}
              bgColor="transparent"
              p={0}
              h="fit-content"
              onClick={() => setShowComment(!showComment)}
              _hover={{ bgColor: "transparent" }}
            />
            <Text fontSize="13px">{comments?.length ?? 0} Comment</Text>
          </Stack>
        </Flex>
        <Stack spacing={1} alignItems="center">
          <ShareIcon />
          <Text fontSize="13px">{shares} Share</Text>
        </Stack>
      </Flex>
      {showComment && (
        <Box>
          <Flex alignItems="flex-start" gap={3}>
            <Avatar name="F F" />
            <Stack spacing={3} w="full" maxW="md">
              <Input
                placeholder="Add a comment"
                _placeholder={{
                  fontSize: "10px",
                }}
              />
              <Button
                variant="brandPrimary"
                py={0.5}
                px={4}
                fontSize="xs"
                w="fit-content"
              >
                Post
              </Button>
            </Stack>
          </Flex>
          {comments &&
            comments.map(
              ({
                user: name,
                job,
                date,
                reply,
                likes: replyLikes,
                replies,
              }) => (
                <Box my={6}>
                  <Stack spacing={3} bgColor="#F9FAFB" py={6} ml={-5}>
                    <PostItem name={name} job={job} time={date} post={reply} />
                  </Stack>
                  <Flex
                    maxW="lg"
                    mx="auto"
                    color="primary.500"
                    gap={10}
                    my={3.5}
                  >
                    <Stack spacing={1} alignItems="center">
                      <LikeIcon />
                      <Text fontSize="13px">{replyLikes} Likes</Text>
                    </Stack>
                    <Stack spacing={1} alignItems="center">
                      <ReplyIcon />
                      <Text fontSize="13px">{replies} Replies</Text>
                    </Stack>
                  </Flex>
                </Box>
              )
            )}
        </Box>
      )}
    </Stack>
  );
};

export default Post;
