import React from "react";
import { Box, Text } from "@chakra-ui/react";

const Termination = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        The Company may terminate your access and account to the website and its
        related services or any part at any time, without notice, in case of
        your violation of the Terms.
      </Text>
    </Box>
  );
};

export default Termination;
