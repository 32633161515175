import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CircleCloseIcon, PencilIcon, PlusIcon } from "../../Icons";
import AddGoalStepModal from "../AddGoalStepModal";
import { OrganizationApi } from "../../../service/organization/OrganizationApi.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import AutoComplete from "../../AutoComplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ProfileApi } from "../../../service/profile/ProfileApi.service";
import { month } from "../../../mockDB";
import { JobsApiService } from "../../../service/jobs/JobsApi.service";

const AchieveGoalModal = ({ isOpen, onClose, goalId }) => {
  const {
    isOpen: isGoalStepModalOpen,
    onClose: onCloseGoalStepModal,
    onOpen: onOpenGoalStepModal,
  } = useDisclosure();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [userGoalSteps, setUserGoalSteps] = useState([]);
  const [currentGoalStep, setCurrentGoalStep] = useState();
  const queryClient = useQueryClient();
  const [goalDetails, setGoalDetails] = useState();
  const [error, setError] = useState();
  const toast = useToast();

  const { data: goalSteps } = useQuery({
    queryKey: ["goalsteps"],
    queryFn: () => ProfileApi.GetGoalStepsById({ userGoalId: goalId }),
    enabled: !!goalId,
  });

  const { data: responseData } = useQuery({
    queryKey: ["user-goal" + goalId],
    queryFn: () => ProfileApi.GetUserGoalById({ id: goalId }),
    enabled: !!goalId,
  });

  useEffect(() => {
    if (responseData) {
      const { data, error } = responseData;
      if (data && data.userGoal) {
        setGoalDetails(data.userGoal);
      } else if (error) {
        setError(error);
      }
    }
  }, [responseData]);

  const [goalToAchieve, setGoalToAchieve] = useState({
    userGoalId: "",
    jobId: "",
    userGoalSteps: [],
    startMonth: 0,
    startYear: 0,
    endMonth: 0,
    endYear: 0,
    countryId: "",
    organizationId: "",
  });
  const AchieveGoalSchema = Yup.object().shape({
    userGoalId: Yup.string().required("Required"),
    startMonth: Yup.number().required("Required"),
    startYear: Yup.number().required("Required"),
    endMonth: Yup.number().required("Required"),
    endYear: Yup.number().required("Required"),
    organizationId: Yup.string().required("Required"),
    userGoalSteps: Yup.array().required("Required"),
  });

  const { data: companies } = useQuery({
    queryKey: ["companies"],
    queryFn: () => OrganizationApi.GetCompanies(),
  });

  const { data: jobs } = useQuery({
    queryKey: ["jobs"],
    queryFn: () => JobsApiService.GetJobs(),
  });

  useEffect(() => {
    if (goalDetails) {
      const transformedSteps = goalDetails?.userGoalSteps?.map((step) => {
        const { id, notes } = step;
        return { userGoalStepId: id, notes: notes ?? "" };
      });
      const goalData = {
        userGoalId: goalDetails.id,
        userGoalSteps: transformedSteps,
        organizationId: goalDetails.organizationId ?? "",
        jobId: goalDetails.jobId ?? "",
        startMonth: goalDetails.startMonth ?? 0,
        startYear: goalDetails.startYear ?? 0,
        endMonth: goalDetails.endMonth ?? 0,
        endYear: goalDetails.endYear ?? 0,
      };
      setGoalToAchieve({ ...goalData });
      setValues(goalData);
      setUserGoalSteps(goalDetails.userGoalSteps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalDetails]);

  const { mutate: achieveGoal } = useMutation({
    mutationFn: async (values) => await ProfileApi.AchieveUserGoal(values),
    onSuccess: ({ data, error }) => {
      setSubmitting(false);
      if (data) {
        queryClient.invalidateQueries({ queryKey: ["userGoals"] });
        toast({
          title: "Goal achieved!",
          status: "success",
        });
        onClose();
      }
      if (error) {
        toast({
          title: error.defaultMessage,
          status: "error",
        });
        onClose();
      }
    },
    onError: (error) => {
      toast({
        title: `Oops, something is wrong`,
        status: "error",
      });
    },
  });

  const { mutate: editGoal } = useMutation({
    mutationFn: async (values) => await ProfileApi.UpdateUserGoal(values),
    onSuccess: ({ data, error }) => {
      setSubmitting(false);
      if (data) {
        toast({
          title: "Goal edit successfully",
          status: "success",
        });
        onClose();
      }
      if (error) {
        toast({
          title: error.defaultMessage,
          status: "error",
        });
        onClose();
      }
    },
    onError: (error) => {
      toast({
        title: `Oops, something is wrong`,
        status: "error",
      });
    },
  });

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: goalToAchieve,
    validationSchema: AchieveGoalSchema,
    onSubmit: (values) => {
      return goalDetails.status.toLowerCase() === "achieved"
        ? editGoal(values)
        : achieveGoal(values);
    },
  });

  const removeGoalStep = (id) => {
    const updatedUserGoalSteps = userGoalSteps.filter(
      (goalStep) => goalStep.id !== id
    );
    const updatedGoalSteps = values.userGoalSteps.filter(
      (goalStep) => goalStep.userGoalStepId !== id
    );
    setUserGoalSteps(updatedUserGoalSteps);
    setFieldValue("userGoalSteps", updatedGoalSteps);
  };

  useEffect(() => {
    if (
      !!values.userGoalId &&
      !!values.userGoalSteps?.length > 0 &&
      !!values.jobId &&
      !!values?.organizationId &&
      !!values.startMonth &&
      !!values.startYear &&
      !!values.endMonth &&
      !!values.endYear
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [values]);

  const handleGoalStep = (currGoalStep) => {
    const updatedUserGoalSteps = values.userGoalSteps
      .filter(
        (goalStep) => goalStep.userGoalStepId !== currGoalStep.userGoalStepId
      )
      .concat(currGoalStep);
    const goalstep = userGoalSteps.findIndex(
      (step) => currGoalStep.userGoalStepId === step.id
    );
    const updatedGoalSteps = userGoalSteps
      .filter((goalStep) => goalStep.id !== currGoalStep.userGoalStepId)
      .concat({ ...userGoalSteps[goalstep], notes: currGoalStep.notes });
    setUserGoalSteps(updatedGoalSteps);
    setFieldValue("userGoalSteps", updatedUserGoalSteps);
    onCloseGoalStepModal();
  };

  return (
    <>
      <Modal id="achieveGoal" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        {!error && !!goalDetails && (
          <ModalContent borderRadius="20px">
            <ModalHeader>
              {goalDetails.status.toLowerCase() === "achieved"
                ? "Edit goal milestone"
                : "Mark goal as achieved"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack as="form" spacing={5} onSubmit={handleSubmit}>
                <FormControl>
                  <FormLabel>Goal</FormLabel>
                  <Text
                    bgColor="#F9FAFB"
                    px={4}
                    py={2}
                    fontSize="sm"
                    fontWeight="medium"
                    w="fit-content"
                  >
                    {goalDetails.value ?? goalDetails.title}
                  </Text>
                </FormControl>
                <FormControl isInvalid={!!errors.jobId && touched.jobId}>
                  <FormLabel htmlFor="jobId">Company/Institute*</FormLabel>
                  <AutoComplete
                    name="companies"
                    options={companies && companies.data.values}
                    handleClick={(value) =>
                      setFieldValue("organizationId", value)
                    }
                    value={values.organizationId}
                  />
                  <FormErrorMessage>{errors.jobId}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel>Position/Course*</FormLabel>
                  <AutoComplete
                    name="jobs"
                    options={jobs && jobs.data.values}
                    handleClick={(value) => setFieldValue("jobId", value)}
                    value={values.jobId}
                  />
                  <FormErrorMessage>{errors.jobId}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel>Start date</FormLabel>
                  <Flex gap={3} flexDir={{ base: "column", md: "row" }}>
                    <Select
                      placeholder="Month"
                      onChange={(e) =>
                        setFieldValue("startMonth", e.target.value)
                      }
                      value={values.startMonth}
                    >
                      {month.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </Select>
                    <Select
                      placeholder="Year"
                      onChange={(e) =>
                        setFieldValue("startYear", e.target.value)
                      }
                      value={values.startYear}
                    >
                      <option value="2024">2024</option>
                    </Select>
                  </Flex>
                </FormControl>
                <FormControl>
                  <FormLabel>End date</FormLabel>
                  <Flex gap={3} flexDir={{ base: "column", md: "row" }}>
                    <Select
                      placeholder="Month"
                      onChange={(e) =>
                        setFieldValue("endMonth", e.target.value)
                      }
                      value={values.endMonth}
                    >
                      {month.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </Select>
                    <Select
                      placeholder="Year"
                      onChange={(e) => setFieldValue("endYear", e.target.value)}
                      value={values.endYear}
                    >
                      <option value="2024">2024</option>
                    </Select>
                  </Flex>
                </FormControl>

                <Box>
                  <FormLabel>Goal step(s)</FormLabel>
                  <Box mt={2} mb={5}>
                    {!!userGoalSteps && (
                      <Box>
                        {userGoalSteps?.map((step) => (
                          <Box key={step.id} minH="30px">
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Text fontSize="sm">{step.title}</Text>
                              <Flex alignItems="center">
                                <IconButton
                                  icon={<PencilIcon color="#099137" />}
                                  variant="link"
                                  onClick={() => {
                                    setCurrentGoalStep(step);
                                    onOpenGoalStepModal();
                                  }}
                                />

                                <IconButton
                                  icon={<CircleCloseIcon color="#D42620" />}
                                  variant="link"
                                  onClick={() => removeGoalStep(step.id)}
                                />
                              </Flex>
                            </Flex>
                            {!!step.notes && (
                              <Box px={3} py={2} bgColor="#FFF8F5">
                                <Text fontSize="xs">{step.notes}</Text>
                              </Box>
                            )}
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                  <Flex
                    as="button"
                    alignItems="center"
                    px={3}
                    py={1}
                    gap={1}
                    color="primary.500"
                    bgColor="#E3EFFC"
                    borderRadius="lg"
                    w="fit-content"
                    mt={2}
                    _hover={{
                      cursor: "pointer",
                    }}
                    onClick={() => onOpenGoalStepModal()}
                    disabled={
                      goalSteps?.data.values.length === userGoalSteps.length
                    }
                    _disabled={{
                      backgroundColor: "#ccc",
                      textColor: "white",
                      opacity: 0.5,
                      cursor: "not-allowed",
                    }}
                  >
                    <PlusIcon />
                    <Text fontSize="sm" fontWeight="medium">
                      Add goal step
                    </Text>
                  </Flex>
                </Box>

                <Button
                  type="submit"
                  variant="brandPrimary"
                  isDisabled={buttonDisabled}
                  isLoading={isSubmitting}
                  mt={12}
                  mb={10}
                  py={4}
                >
                  Save
                </Button>
              </Stack>
            </ModalBody>
          </ModalContent>
        )}
      </Modal>
      {isGoalStepModalOpen && (
        <AddGoalStepModal
          isOpen={isGoalStepModalOpen}
          onClose={onCloseGoalStepModal}
          userGoalId={goalId}
          currentGoalStep={currentGoalStep}
          handleGoalStep={handleGoalStep}
        />
      )}
    </>
  );
};

export default AchieveGoalModal;
