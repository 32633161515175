import React from "react";
import { Box, Text } from "@chakra-ui/react";

const Security = () => {
  return (
    <Box w="100%" my="40px">
      <Text w="100%" lineHeight={1.5} my="20px">
        We seek to use reasonable organizational, technical and administrative
        measures to protect personal data within our organization.
        Unfortunately, no transmission or storage system can be guaranteed to be
        completely secure, and transmission of information via the Internet is
        not completely secure. If you have reason to believe that your
        interaction with us is no longer secure (for example, if you feel that
        the security of any account you might have with us has been
        compromised), please immediately notify us of the problem by contacting
        us.
      </Text>
    </Box>
  );
};

export default Security;
