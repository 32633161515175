import {
  Avatar,
  Badge,
  Box,
  Stack,
  Text,
  UnorderedList,
  Link as ChakraLink,
  ListItem,
  Divider,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NavLink as ReactRouterLink, useLocation } from "react-router-dom";
import {
  CloseIcon,
  QuestionCircleIcon,
  SettingsIcon,
  SignOutIcon,
} from "../Icons";
import { logout, GetUserInfo } from "../../utils";
import { paths } from "../../routes";

const MobileNav = ({ show, showMenu }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const userInfo = GetUserInfo();
    setUser(userInfo);
  }, []);

  const Link = ({ to, children }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
    return (
      <ChakraLink
        to={to}
        as={ReactRouterLink}
        variant="brandLink"
        _activeLink={
          isActive && {
            color: "primary.400",
          }
        }
        fontSize="sm"
        color="grey.700"
      >
        {children}
      </ChakraLink>
    );
  };
  return (
    <Box
      pos={{ base: "absolute", lg: "static" }}
      right={0}
      flexDirection={{ base: "column", lg: "row" }}
      w={{ base: "full", lg: "auto" }}
      h={{ base: "100vh", lg: "auto" }}
      bgColor="white"
      top={0}
      px={{ base: 4, lg: 0 }}
      py={{ base: 7, lg: 0 }}
      zIndex={10}
      display={show ? "block" : "none"}
      maxW="sm"
    >
      <Box ml="auto" w="max-content">
        <CloseIcon onClick={showMenu} />
      </Box>
      <Stack spacing={6}>
        <Box px={2}>
          <Avatar
            name={`${user.fname} ${user.lname}`}
            // src={user.image}
            size="lg"
          />
          <Box>
            <Text
              mt={3}
              mb={1}
              fontSize="sm"
              fontWeight={500}
              color="primary.500"
            >
              {`${user.fname} ${user.lname}`}
            </Text>
            <Text fontSize="12px" color="grey.600" fontWeight={500} mb={2}>
              Business Analyst
            </Text>
            <Badge
              bgColor="primary.500"
              px={2}
              color="white"
              textTransform="capitalize"
            >
              Public Profile
            </Badge>
          </Box>
        </Box>

        <Divider />

        <UnorderedList
          display="flex"
          flexDir="column"
          gap={8}
          styleType="none"
          ml={0}
          px={2}
        >
          <ListItem>
            <Link to={paths.FOLLOWING}>Following</Link>
          </ListItem>
          <ListItem>
            <Link to={paths.BASED_ON_GOAL}>Based on your goal</Link>
          </ListItem>
          <ListItem>
            <Link to={paths.COMPANIES}>Companies Following</Link>
          </ListItem>
          <ListItem>
            <Link to={paths.COMMUNITY}>Community</Link>
          </ListItem>
        </UnorderedList>
        <Divider />
        <UnorderedList
          display="flex"
          flexDir="column"
          gap={8}
          styleType="none"
          ml={0}
          px={2}
        >
          <ListItem display="flex" alignItems="center" gap={2}>
            <SettingsIcon boxSize={5} />
            <Link to={paths.SETTINGS}>Settings</Link>
          </ListItem>
          <ListItem display="flex" alignItems="center" gap={2}>
            <QuestionCircleIcon boxSize={5} />
            <Link to="/companies-following">Help/FAQs</Link>
          </ListItem>
          <ListItem display="flex" alignItems="center" gap={2} color="red.400">
            <SignOutIcon boxSize={5} />
            <Button
              variant="link"
              color="red.400"
              fontSize="sm"
              onClick={logout}
            >
              Logout
            </Button>
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};

export default MobileNav;
